import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu, Avatar, Space, Alert, Typography } from 'antd';
import { CloudTwoTone, ExportOutlined, LogoutOutlined, GlobalOutlined, SettingOutlined } from '@ant-design/icons';
import { Route, Switch, Link, useLocation, Redirect } from "react-router-dom";
import OrganizationProps from './interfaces/OrganizationProps';
import { Organization, Settings as Settings_ } from 'nuaudit-browser-autogen';
import prettyBytes from "pretty-bytes";

import formatInitials from './utils/formatInitials';

import Trails from './pages/Trails'
import Trail from './pages/Trail'
import Resources from './pages/Resources';
import Resource from './pages/Resource';
import Actors from './pages/Actors';
import Actor from './pages/Actor';
import Organizations from './pages/Organizations';
import NotFound from './pages/NotFound';
import Nuaudit from './components/Nuaudit';
import Settings from './pages/Settings';
import { getSettingsForOrganization } from './services/rest';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

interface RouteDetail {
  path: string
  component: FC<OrganizationProps>
  name: string
  nav: boolean
}

const routes: RouteDetail[] = [
  {
    path: "/trails",
    component: Trails,
    name: "Trails",
    nav: true,
  },
  {
    path: "/trails/:id",
    component: Trail,
    name: "Trail",
    nav: false,
  },
  {
    path: "/actors",
    component: Actors,
    name: "Actors",
    nav: true,
  },
  {
    path: "/actors/:id",
    component: Actor,
    name: "Actor",
    nav: false,
  },
  {
    path: "/resources",
    component: Resources,
    name: "Resources",
    nav: true,
  },
  {
    path: "/resources/:id",
    component: Resource,
    name: "Resource",
    nav: false,
  },
  {
    path: "/organizations",
    component: Organizations,
    name: "Organizations",
    nav: false,
  },
  {
    path: "/settings",
    component: Settings,
    name: "Settings",
    nav: false,
  }
];

interface MainProps {
  selectedOrganization: Organization,
  organizations: Organization[]
  setSelectedOrganization: (organization: Organization) => void
  refresh: () => void
}

const Main: FC<MainProps> = ({selectedOrganization, organizations, setSelectedOrganization, refresh}) => {
  const location = useLocation();
  const [settings, setSettings] = useState<Settings_ | null>()

  const getSelectedKeys = () => {
    const path = [...routes].reverse().find((route) => location.pathname.startsWith(route.path))?.path;
    if (path) {
      return [path]
    } else {
      return []
    }
  }

  useEffect(()=> {
    (async () => {
      let settings: Settings_ | null = null
      try {
          settings = await getSettingsForOrganization(selectedOrganization.id)
      } catch(error: any) {}
      setSettings(settings)
    })()
  },[selectedOrganization])

  return (
    <Layout style={{minHeight:"100%"}}>
      <Header>
        <Nuaudit />
        <Menu theme="dark" mode="horizontal" selectedKeys={getSelectedKeys()}>
          {
              routes.filter((route) => route.nav === true).map((route) => 
                  <Menu.Item key={route.path}><Link to={route.path} >{route.name}</Link></Menu.Item>
              )
          } 
          <SubMenu
            style={{marginLeft: "auto"}} 
            title={<Space><Avatar>{formatInitials(selectedOrganization.name)}</Avatar>{selectedOrganization.name}</Space>}
            popupOffset={[0,0]}
          >
            {organizations.map((organization) => organization.id !== selectedOrganization.id
              ? <Menu.Item onClick={() => setSelectedOrganization(organization)}><Space><Avatar>{formatInitials(organization.name)}</Avatar>{organization.name}</Space></Menu.Item>
              : <></>
            )}
            <Menu.Item icon={<GlobalOutlined />}><Link to={"/organizations"}>Manage Organizations</Link></Menu.Item>
          </SubMenu>  
          <SubMenu title={"Additional Options"} icon={<CloudTwoTone twoToneColor="#B56357" />} popupOffset={[0,0]}>
            <Menu.Item onClick={() => window.open("/api/docs")} icon={<ExportOutlined/>}>API Documentation</Menu.Item>
            <Menu.Item  icon={<SettingOutlined/>}><Link to={"/settings"} >Settings</Link></Menu.Item>
            <Menu.Item onClick={() => window.location.assign("/api/logout")}  icon={<LogoutOutlined />}>Logout</Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      {settings?.usageLimit && <Alert type="info" message={
        <Text>The current plan has a <Text strong>{prettyBytes(settings.usageLimit)}</Text> limit. Update the organization's billing details to move to the unlimited plan.</Text>
      } banner closable={true} />}
      <Content style={{ padding: '20px 20px 0px 20px', minHeight: "100%", display: "flex", flexFlow: "column" }}>
        <Switch>
          {routes.map((route) =>
              <Route exact path={route.path}>
                <div style={{  padding: "24px", background: "#fff", flexGrow: 1}}>
                  <route.component organizationId={selectedOrganization.id} refresh={refresh} />
                </div>
              </Route>
          )}
          <Route exact path="/">
            <Redirect to="/trails" />
          </Route>
          <Route path="/">
            <NotFound message="Sorry, the page you visited does not exist." linkTo="/trails"/>
          </Route>
        </Switch>
      </Content>
      <Footer style={{ textAlign: 'center' }}>nuaudit ©2021</Footer>
    </Layout>
  )
}


export default Main;