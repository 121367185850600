import { DeleteTwoTone, EditTwoTone, ExclamationCircleTwoTone, PlusOutlined, StopTwoTone } from "@ant-design/icons";
import { Avatar, Button, Drawer, List, message, Space, Spin, Typography, Checkbox, Divider, Tag, Popover, Form, Input, Popconfirm } from "antd";
import { Invite, InviteMutation, Role, User } from "nuaudit-browser-autogen";
import React, { FC, useEffect, useState } from "react"
import { addUserToRole, createInvite, deleteInvite, listInvites, listRoles, listRolesForUser, listUsers, removeUserFromRole } from "../services/rest";
import formatInitials from "../utils/formatInitials";
import getErrorText from "../utils/getErrorText";

const {Text} = Typography;

interface UsersItemProps {
    organizationId: string
    user: User
}

const UsersItem: FC<UsersItemProps> = ({organizationId, user}) => {
    const [roles, setRoles] = useState<Role[]>();
    const [userRoles, setUserRoles] = useState<Role[]>();
    const [selectedUserRoles, setSelectedUserRoles] = useState<Role[]>();
    const [hasChanges, setHasChanges] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [editVisibile, setEditVisible] = useState<boolean>(false);
    const [editLoading, setEditLoading] = useState<boolean>(true);

    const discardChanges = () => {
        setHasChanges(false)
        setSelectedUserRoles(userRoles)
    }

    const selectRole = async (role: Role, checked: boolean) => {
        setHasChanges(true)
        if (checked) {
            setSelectedUserRoles(selectedUserRoles?.concat(role))
        } else {
            setSelectedUserRoles(selectedUserRoles?.filter(userRole => userRole.id !== role.id))
        }
    }

    const roleAdditions = () => selectedUserRoles?.filter(selectedUserRole => 
        !userRoles?.some(userRole => userRole.id === selectedUserRole.id)
    )

    const roleDeletions = () => userRoles?.filter(userRole => 
        !selectedUserRoles?.some(selectedUserRole => userRole.id === selectedUserRole.id)
    )

    const saveChanges = async () => {
        setSavingChanges(true)
        const additions = roleAdditions()
        const deletions = roleDeletions()
        if (additions && additions.length > 0) {
            for (const role of additions) {
                try {
                    await addUserToRole(organizationId, role.id, user.id)
                    message.success(`Added User ${user.preferredName} to Role ${role.name}.`)
                } catch(error: any) {
                    message.error(getErrorText(error))
                }
            }
        }
        if (deletions && deletions?.length > 0) {
            for (const role of deletions) {
                try {
                    await removeUserFromRole(organizationId, role.id, user.id)
                    message.success(`Removed User ${user.preferredName} from Role ${role.name}.`)
                } catch(error: any) {
                    message.error(getErrorText(error))
                }
            }
        }
        setUserRoles(selectedUserRoles)
        setHasChanges(false)
        setSavingChanges(false)
    }

    const editRoles = async (organizationId: string, userId: string) => {
        setEditLoading(true)
        setEditVisible(true)
        const [roles, userRoles] = await Promise.all([listRoles(organizationId), listRolesForUser(organizationId, userId)])
        setRoles(roles)
        setUserRoles(userRoles)
        setSelectedUserRoles(userRoles)
        setEditLoading(false)
    }

    return <List.Item key={user.id}>
            <List.Item.Meta
            avatar={
                <Avatar>{user.fullName ? formatInitials(user.fullName) : formatInitials(user.email)}</Avatar>
            }
            title={user.fullName}
            description={user.email}
        />
        <Space wrap>
            <Button shape="round" icon={<EditTwoTone />} onClick={() => editRoles(organizationId, user.id)}>Edit Roles</Button>
            <Button danger shape="round" icon={<StopTwoTone twoToneColor="#ff7875"/>}>Disable</Button>
        </Space>
        <Drawer
            title={<><Text>Roles </Text><Text type="secondary">{user.fullName || user.email}</Text></>}
            placement={"right"}
            visible={editVisibile}
            onClose={() => setEditVisible(false)}
            width={384}
        >
            <Spin spinning={editLoading}>
                {
                    userRoles !== undefined && (roles
                        ?   roles.map(role => 
                                <>
                                    <Checkbox checked={selectedUserRoles?.some(userRole => userRole.id === role.id)} onChange={(e) => selectRole(role, e.target?.checked)}>
                                        {role.name} {role.isSystemRole && <Text type="secondary">System Role</Text>}
                                    </Checkbox>
                                    <br />
                                </>
                            )
                        :   <Text>You have no Roles.</Text>)
                        
                }
                <Divider />
                <Space direction={"vertical"}>
                    <Button shape="round" block={true} disabled={!hasChanges || savingChanges} onClick={() => discardChanges()}>Discard Role Changes</Button>
                    <Button shape="round" block={true} disabled={!hasChanges} onClick={() => saveChanges()} type="primary" loading={savingChanges}>Save Role Changes</Button>
                </Space>
            </Spin>
        </Drawer>
    </List.Item>
} 

interface UsersProps {
    organizationId: string
}

const Users: FC<UsersProps> = ({organizationId}) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(true);
    const [inviteVisible, setInviteVisible] = useState<boolean>(false)
    const [inviting, setInviting] = useState<boolean>(false)
    const [users, setUsers] = useState<User[]>([]);
    const [invites, setInvites] = useState<Invite[]>([]);

    const handleInviteVisibleChange = (visible: boolean) => {
        form.resetFields()
        setInviteVisible(visible)
    }

    const onFinish = async (inviteMutation: InviteMutation) => {
        setInviting(true)
        try {
            const invite = await createInvite(organizationId, inviteMutation)
            message.success(`Sent invite to ${invite.email}.`)
            let invites: Invite[] = [];
            invites = await listInvites(organizationId)
            setInvites(invites)
            setInviteVisible(false)
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setInviting(false)
    }

    const _deleteInvite = async (invite: Invite) => {
        setLoading(true)
        try {
            await deleteInvite(organizationId, invite.id)
            setInvites(invites.filter(_invite =>  _invite.id !== invite.id))
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        message.success("Deleted Invite.")
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            let users: User[] = [];
            let invites: Invite[] = [];
            try {
                users = await listUsers(organizationId)
                invites = await listInvites(organizationId)
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setUsers(users)
            setInvites(invites)
            setLoading(false)
        })()
    }, [organizationId]);

    return  <>
        <List loading={loading} dataSource={users} renderItem={user =>
            <UsersItem organizationId={organizationId} user={user} />
        }/>
        <Divider orientation="left">User Invites</Divider>
        <Popover 
            visible={inviteVisible}
            onVisibleChange={handleInviteVisibleChange}
            trigger="click"
            placement="bottomLeft"
            content={
                <Space direction="vertical">
                    <Form layout="vertical" requiredMark={false} onFinish={onFinish} form={form}>
                        <Form.Item
                        label="Invite a User"
                        name="email"
                        rules={[{ required: true, message: 'Required.' }]}
                        >
                            <Input placeholder="Email"  autoComplete="off" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={inviting}>Invite</Button>
                        </Form.Item>
                    </Form>
                </Space>
            }>
            <Button size="small" shape="round" type="dashed" icon={<PlusOutlined />} disabled={inviting}>Invite User</Button>
        </Popover>
        <List loading={loading} dataSource={invites} renderItem={invite => (
            <List.Item key={invite.id}>
                <List.Item.Meta
                    description={
                        <Space>
                            {invite.email}
                            <Tag color={invite.status === "ACCEPTED" ? "green" : "volcano"}>
                                {invite.status}
                            </Tag>
                        </Space>
                    }
                />
                <Popconfirm
                            title="Are you sure to delete this Invite?"
                            onConfirm={() => _deleteInvite(invite)}
                            okText="Yes"
                            cancelText="No"
                            icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                        >
                    <Button danger size="small" shape="round" type="dashed" icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>Delete Invite</Button>
                </Popconfirm>
            </List.Item>
        )}/>
    </>
}

export default Users