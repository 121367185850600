import { APIClientMutation, createConfiguration, TrailsApi, TrailMutation, APIKeyMutation, APIClientsApi, APIKeysApi, RecordsApi, OrganizationsApi, OrganizationMutation, ActorsApi, ResourcesApi, UsersApi, RecordMutation, RolesApi, RoleMutation, PermissionsApi, PermissionMutation, PermissionDeletion, InvitesApi, AcceptInviteMutation, InviteMutation, ServerConfiguration, SettingsApi, Settings, UsageAndBillingApi, BillingMutation } from "nuaudit-browser-autogen";

const url = process.env.REACT_APP_BACKEND_URL || "https://app.nuaudit.com/api"
const configuration = createConfiguration({baseServer: new ServerConfiguration<{  }>(url, {  })})

const apiClientsAPI = new APIClientsApi(configuration)
const apiKeysAPI = new APIKeysApi(configuration)
const trailsApi = new TrailsApi(configuration)
const recordsApi = new RecordsApi(configuration)
const organizationsApi = new OrganizationsApi(configuration)
const actorsApi =  new ActorsApi(configuration)
const resourcesApi =  new ResourcesApi(configuration)
const usersApi = new UsersApi(configuration)
const rolesApi = new RolesApi(configuration)
const permissionsApi = new PermissionsApi(configuration)
const invitesApi = new InvitesApi(configuration)
const settingsApi = new SettingsApi(configuration)
const usageAndBillingApi = new UsageAndBillingApi(configuration)

//
// API Client
//
export const getApiClient = async (organizationId: string, id: string) => {
    return await apiClientsAPI.getApiClient(organizationId, id)
}

export const listApiClients = async (organizationId: string) => {
    return await apiClientsAPI.listApiClients(organizationId)
}

export const createApiClient = async (organizationId: string, apiClientMutation: APIClientMutation) => {
    return await apiClientsAPI.createApiClient(organizationId, apiClientMutation)
}

export const updateApiClient = async (organizationId: string, apiClientId: string, apiClientMutation: APIClientMutation) => {
    return await apiClientsAPI.updateApiClient(organizationId, apiClientId, apiClientMutation)
}

export const deleteApiClient = async (organizationId: string, apiClientId: string) => {
    return await apiClientsAPI.deleteApiClient(organizationId, apiClientId)
}

export const listRolesForApiClient = async (organizationId: string, apiClientId: string) => {
    return await apiClientsAPI.listRolesForApiClient(organizationId, apiClientId)
}

//
// API Keys
//
export const createApiKey = async (organizationId: string, apiClientId: string, apiKeyMutation: APIKeyMutation) => {
    return await apiKeysAPI.createApiKey(organizationId, apiClientId, apiKeyMutation)
}

export const updateApiKey = async (organizationId: string, apiClientId: string, id: string, apiKeyMutation: APIKeyMutation) => {
    return await apiKeysAPI.updateApiKey(organizationId, apiClientId, id, apiKeyMutation)
}

export const listApiKeys = async (organizationId: string, apiClientId: string) => {
    return await apiKeysAPI.listApiKeysForApiClient(organizationId, apiClientId)
}

export const deleteApiKey = async (organizationId: string, apiClientId: string, id: string) => {
    return await apiKeysAPI.deleteApiKey(organizationId, apiClientId, id)
}

//
// Trails
//
export const createTrail = async (organizationId: string, trailMutation: TrailMutation) => {
    return await trailsApi.createTrail(organizationId, trailMutation)
}

export const updateTrail = async (organizationId: string, trailId: string, trailMutation: TrailMutation) => {
    return await trailsApi.updateTrail(organizationId, trailId, trailMutation)
}

export const getTrail = async (organizationId: string, id: string) => {
    return await trailsApi.getTrail(organizationId, id)
}

export const listTrails = async (organizationId: string) => {
    return await trailsApi.listTrails(organizationId)
}

export const deleteTrail = async (organizationId: string, id: string) => {
    return await trailsApi.deleteTrail(organizationId, id)
}

///
/// Records
///
export const listTrailRecords = async (organizationId: string, id: string) => {
    return await recordsApi.listTrailRecords(organizationId, id)
}

export const listActorRecords = async (organizationId: string, id: string) => {
    return await recordsApi.listActorRecords(organizationId, id)
}

export const listResourceRecords = async (organizationId: string, id: string, afterDate?: string, limit?: number) => {
    return await recordsApi.listResourceRecords(organizationId, id, afterDate, limit)
}

export const reindex = async (organizationId: string, trailId: string, recordId: string) => {
    return await recordsApi.reindexTrailRecord(organizationId, trailId, recordId)
}

///
/// Organizations
///
export const listOrganizations = async () => {
    return await organizationsApi.listOrganizations()
}

export const createOrganization = async (organizationMutation: OrganizationMutation) => {
    return await organizationsApi.createOrganization(organizationMutation)
}

export const deleteOrganization = async (organizationId: string) => {
    return await organizationsApi.deleteOrganization(organizationId)
}

///
/// Actors
///
export const listActors = async (organizationId: string) => {
    return await actorsApi.listActors(organizationId)
}

export const getActor = async (organizationId: string, actorId: string) => {
    return await actorsApi.getActor(organizationId, actorId)
}

///
/// Resources
///
export const listResources = async (organizationId: string) => {
    return await resourcesApi.listResources(organizationId)
}

export const getResource = async (organizationId: string, resourceId: string) => {
    return await resourcesApi.getResource(organizationId, resourceId)
}

///
/// Users
///
export const listUsers = async (organizationId: string) => {
    return await usersApi.listUsers(organizationId)
}

export const listRolesForUser = async (organizationId: string, userId: string) => {
    return await usersApi.listRolesForUser(organizationId, userId)
}

///
/// Records
///
export const createRecord = async (organizationId: string, trailId: string, recordMutation: RecordMutation) => {
    return await recordsApi.createRecord(organizationId, trailId, recordMutation)
}

///
/// Roles
///
export const listRoles = async (organizationId: string) => {
    return await rolesApi.listRoles(organizationId)
}

export const createRole = async (organizationId: string, roleMutation: RoleMutation) => {
    return await rolesApi.createRole(organizationId, roleMutation)
}

export const getRole = async(organizationId: string, roleId: string) => {
    return await rolesApi.getRole(organizationId, roleId)
}

export const updateRole = async (organizationId: string, roleId: string, roleMutation: RoleMutation) => {
    return await rolesApi.updateRole(organizationId, roleId, roleMutation)
}

export const deleteRole = async (organizationId: string, roleId: string) => {
    return await rolesApi.deleteRole(organizationId, roleId)
}

export const addUserToRole = async (organizationId: string, roleId: string, userId: string) => {
    return await rolesApi.addUserToRole(organizationId, roleId, userId)
}

export const removeUserFromRole = async (organizationId: string, roleId: string, userId: string) => {
    return await rolesApi.removeUserFromRole(organizationId, roleId, userId)
}

export const addApiClientToRole = async (organizationId: string, roleId: string, apiClientId: string) => {
    return await rolesApi.addApiClientToRole(organizationId, roleId, apiClientId)
}

export const removeApiClientFromRole = async (organizationId: string, roleId: string, apiClientId: string) => {
    return await rolesApi.removeApiClientFromRole(organizationId, roleId, apiClientId)
}
///
/// Permissions
///
export const addPermissions = async (organizationId: string, roleId: string, permissionMutations: PermissionMutation[]) => {
    return await permissionsApi.addPermissions(organizationId, roleId, permissionMutations)
}

export const removePermissions = async (organizationId: string, roleId: string, permissionDeletions: PermissionDeletion[]) => {
    return await permissionsApi.removePermissions(organizationId, roleId, permissionDeletions)
}

///
/// Invites
///
export const acceptInvite = async (acceptInviteMutation: AcceptInviteMutation) => {
    return await invitesApi.acceptInvite(acceptInviteMutation)
}

export const listInvites = async (organizationId: string) => {
    return await invitesApi.listInvites(organizationId)
}

export const createInvite = async (organizationId: string, inviteMutation: InviteMutation) => {
    return await invitesApi.createInvite(organizationId, inviteMutation)
}

export const deleteInvite = async (organizationId: string, inviteId: string) => {
    return await invitesApi.deleteInvite(organizationId, inviteId)
}

///
/// Settings
///
export const getSettingsForOrganization = async (organizationId: string) => {
    return await settingsApi.getSettingsForOrganization(organizationId)
}

export const updateSettingsForOrganization = async (organizationId: string, settings: Settings) => {
    return await settingsApi.updateSettingsForOrganization(organizationId, settings)
}

///
/// Usage and Billing
///
export const listUsage = async (organizationId: string) => {
    return await usageAndBillingApi.listUsage(organizationId)
}

export const createStripeSetupIntent = async (organizationId: string) => {
    return await usageAndBillingApi.createStripeSetupIntent(organizationId)
}

export const getBilling = async (organizationId: string) => {
    return await usageAndBillingApi.getBilling(organizationId)
}

export const updateBilling = async (organizationId: string, billingMutation: BillingMutation) => {
    return await usageAndBillingApi.updateBilling(organizationId, billingMutation)
}

export const deletePaymentMethod = async (organizationId: string, paymentMethodId: string) => {
    return await usageAndBillingApi.deletePaymentMethod(organizationId, paymentMethodId)
}