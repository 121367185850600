import { FC, useEffect, useState } from "react"
import { Area } from '@ant-design/charts';
import { Usage } from "nuaudit-browser-autogen";
import { listUsage } from "../services/rest";
import { Col, DatePicker, Divider, message, Row, Space, Spin, Statistic, Typography } from "antd";
import prettyBytes from "pretty-bytes";
import moment from 'moment';
import getErrorText from "../utils/getErrorText";

const { Title, Text } = Typography;

interface UsagesProps {
    organizationId: string
}

const Usages: FC<UsagesProps> = ({organizationId}) => {
    const [loading, setLoading] = useState(true);
    const [min, setMin] = useState<number>(moment().startOf('month').unix()*1000);
    const [max, setMax] = useState<number>(moment().endOf('month').unix()*1000);
    const [usages, setUsages] = useState<Usage[]>([]);

    const setMonth = (date: moment.Moment | null) => {
        date && setMin(date.startOf('month').unix()*1000)
        date && setMax(date.endOf('month').unix()*1000)
    }

    const calculateUsage = () => {
        const monthlyUsages = usages
            .filter(usage => moment(usage.createdAt).unix()*1000 >= min)
            .filter(usage => moment(usage.createdAt).unix()*1000 <= max)
        return (monthlyUsages.reduce((p, c) => p + c.size, 0) / monthlyUsages.length)
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            let usages: Usage[] = [];
            try {
                usages = await listUsage(organizationId)
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setUsages(usages.reverse());
            setLoading(false)
        })()
    }, [organizationId]);

    return <Spin spinning={loading}>
        <Row>
            <Col span={12}>
                <Title level={3}>Data Usage</Title>
                <Space>
                    <Text>Select Month</Text>
                    <DatePicker onChange={setMonth} defaultValue={moment()} picker="month" />
                </Space>
            </Col>
            <Col>
                <Statistic title="Average Usage" value={calculateUsage() || 0} formatter={(value) => prettyBytes(value as number)}/>
            </Col>
        </Row>
        
        

        <Divider dashed={true} />
        <Area 
            data={usages.map(usage => ({"day": usage.createdAt, "value": usage.size}))}
            height={400}
            xField={"day"}
            yField={"value"}
            point={{
            size:5, 
            shape: "diamond"
            }} 
            xAxis={{
                title: {text: "Date"},
                type: "time",
                min: min,
                max: max
            }}
            yAxis={{
                title: {text: "Usage"},
                label: {
                    formatter: (value: string) => prettyBytes(parseInt(value)) 
                }
            }}
            tooltip={{
                formatter: (datum) => ({name: "Usage", value: prettyBytes(parseInt(datum.value))})
            }}
        />
      </Spin>;
  }

export default Usages