import { DiffOutlined } from "@ant-design/icons";
import { Button, message, Popover, Space, Spin, Typography } from "antd"
import { Record, ResourceRecord } from "nuaudit-browser-autogen"
import React, { FC, useState } from "react"
import { Link } from "react-router-dom"
import { listResourceRecords } from "../services/rest";
import htmlDiff from "../utils/htmlDiff";

const { Text } = Typography;

interface ResourceRecordColProps {
    organizationId: string,
    record: Record
}

const ResourceRecordCol: FC<ResourceRecordColProps> = ({organizationId, record}) => {
    const [prevResourceRecord, setPrevResourceRecord] = useState<ResourceRecord | null>()
     
    const resourceRecord = record.resourceRecord

    const getNextResourceRecord = async (resourceId: string, resourceDate: string) => {
        let nextResourceRecord: ResourceRecord | null = null
        try {
            const records = await listResourceRecords(organizationId, resourceId, resourceDate, 1)
            if (records.length === 1) {
                nextResourceRecord = records[0].resourceRecord
            }
        } catch(error: any) {
            message.error(error?.body?.detail || error)
        }
        return nextResourceRecord
    }
    
    return resourceRecord.resource 
        ?   <>
                <Link to={`/resources/${resourceRecord.resource.id}`}>{resourceRecord.resource.metadata.derivedId}</Link>
                <br />
                <Space wrap>
                    <Text type="secondary">{resourceRecord.resource.metadata.derivedType}</Text>
                    <Popover
                        content={
                            prevResourceRecord !== undefined
                            ? <div dangerouslySetInnerHTML={{ __html: htmlDiff(prevResourceRecord?.data || {}, resourceRecord.data)}}/>
                            : <Spin tip="Fetching Details..."/>
                            
                        }
                        trigger="click"
                        placement="bottomRight"
                        onVisibleChange={(visible: boolean) => {
                            // Lazy load previous records
                            (async () => {
                                if (prevResourceRecord === undefined && resourceRecord.resource && visible) {
                                    const nextResourceRecord = await getNextResourceRecord(resourceRecord.resource.id, record.createdAt)
                                    setPrevResourceRecord(nextResourceRecord)
                                } 
                            })()
                        }}
                    >
                        <Button icon={<DiffOutlined />} shape="circle" size="small" />
                    </Popover>
                </Space>
            </>
        :   <Space wrap>
                <Text type="secondary">Unknown</Text>
                <Popover
                    content={<div dangerouslySetInnerHTML={{ __html: htmlDiff({}, resourceRecord.data)}}/>}
                    trigger="click"
                    placement="bottomRight"
                >
                    <Button icon={<DiffOutlined />} shape="circle" size="small" />
                </Popover>
            </Space>
} 

export default ResourceRecordCol