import { FC, useCallback, useEffect, useState } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { Alert, Button, Form, Input, List, message, Space, Spin, Tag, Typography, Popconfirm } from "antd";
import { Billing as Billing_, PaymentMethod } from "nuaudit-browser-autogen";
import { createStripeSetupIntent, deletePaymentMethod, getBilling, updateBilling } from "../services/rest";
import getErrorText from "../utils/getErrorText";
import { DeleteTwoTone, ExclamationCircleTwoTone, PlusOutlined, StarOutlined, StopTwoTone } from "@ant-design/icons";

const { Title, Text } = Typography;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");

interface CheckoutFormProps {
  organizationId: string
  onCancel?: () => void
  onComplete?: () => void
}

const CheckoutForm: FC<CheckoutFormProps> = ({organizationId, onCancel, onComplete}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setLoading] = useState(false);
  
  const onFinish = async (values: any) => {
    setLoading(true)

    if (!stripe || !elements) {
        return;
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement === null) {
      return;
    }

    try {
      const setupIntent = await createStripeSetupIntent(organizationId)
      const {error} = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: values.name
          }
        }
      })
      if (error) {
          message.error(error.message)
      } else {
          message.success("Added Payment Method.")
      }
    } catch(error: any) {
      message.error(getErrorText(error))
    }
    setLoading(false)
    onComplete && onComplete()
  };

  const cardOptions = {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#1890ff",
          color: "rgba(0, 0, 0, 0.65)",
          fontWeight: 500,
          fontFamily: "Segoe UI, Roboto, Open Sans, , sans-serif",
          fontSize: "15px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": { color: "#fce883" },
          "::placeholder": { color: "#bfbfbf" }
        },
        invalid: {
          iconColor: "#ffc7ee",
          color: "#ffc7ee"
        }
      }
    };

  return (
      <>
        <Title level={3}>Setup Payment Method</Title>
        <Form 
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 14 }}
              onFinish={onFinish}
              labelAlign="right"
        >
          <Form.Item 
              name="name"
              label="Cardholder Name"
              required={false}
              rules={[{ required: true, message: "Input the Cardholder's Name." }]}
          >
              <Input placeholder="Name" />
          </Form.Item>
          <Form.Item 
              name="card"
              label="Card" 
              required={false}
              rules={[{ required: true, message: "Card is required." }]}
          >
              <CardElement options={cardOptions as any} />
          </Form.Item>
          <Space wrap>
            <Button
                shape={"round"}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                disabled={!stripe || !elements}
            >
                Submit
            </Button>
            {onCancel && <Button 
              danger 
              shape="round" 
              icon={<StopTwoTone twoToneColor="#ff7875"/>} 
              onClick={()=> onCancel()}
              disabled={!stripe || !elements || isLoading}

            >
                Cancel
            </Button>}
          </Space>
      </Form>
    </>
  );
};

interface BillingProps {
    organizationId: string
}

const Billing: FC<BillingProps> = ({organizationId}) => {
  const [loading, setLoading] = useState(true)
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false)
  const [billing, setBilling] = useState<Billing_ | null>()

  const updateDefaultPaymentMethod = async (defaultPaymentMethod: string) => {
    setLoading(true)
    try {
      const billing = await updateBilling(organizationId, {defaultPaymentMethod})
      setBilling(billing)
      message.success("Updated default payment method.")
    } catch(error: any) {
      message.error(getErrorText(error))
    }
    setLoading(false)
  }

  const _deletePaymentMethod = async (paymentMethodId: string) => {
    setLoading(true)
    try {
      const billing = await deletePaymentMethod(organizationId, paymentMethodId)
      setBilling(billing)
      message.success("Deleted payment method.")
    } catch(error: any) {
      message.error(getErrorText(error))
    }
    setLoading(false)
  }

  const _getBilling = useCallback(async () => {
    setLoading(true)
    setBilling(undefined)
    let billing: Billing_ | null = null;
    try {
      billing = await getBilling(organizationId)
    } catch(error: any) {
      message.error(getErrorText(error))
    }
    setBilling(billing)
    setLoading(false)
  }, [organizationId])

  useEffect(() => {
    (async () => {
      await _getBilling()
    })()
  }, [_getBilling])

  return <Spin spinning={loading}>
    {billing
      ? billing.paymentMethods.length !== 0
        ? addingPaymentMethod 
          ? <>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  organizationId={organizationId}
                  onCancel={() => setAddingPaymentMethod(false)}
                  onComplete={() => {setAddingPaymentMethod(false); _getBilling()}}
                />
              </Elements>
            </>
          : <>
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={billing.paymentMethods}
                renderItem={(item: PaymentMethod) => (
                  <List.Item actions={item.id === billing.defaultPaymentMethod
                    ? [
                        <Text type="secondary">Default Payment Method</Text>,
                        ...[billing.paymentMethods.length === 1 && <Popconfirm
                          title={<Text strong>Delete this Payment Method?</Text>}
                          placement="bottomRight"
                          onConfirm={() => _deletePaymentMethod(item.id)}
                          okText="Yes"
                          cancelText="No"
                          icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                        >
                          <Button 
                            danger 
                            shape="round" 
                            type="dashed" 
                            icon={<DeleteTwoTone twoToneColor="#ff7875"/>}
                          >
                            Delete 
                          </Button>
                        </Popconfirm>]
                      ] 
                    : [
                      <Button 
                        shape="round" 
                        onClick={() => updateDefaultPaymentMethod(item.id)}
                        icon={<StarOutlined/>}
                      >
                        Default
                      </Button>,
                      <Popconfirm
                        title={<Text strong>Delete this Payment Method?</Text>}
                        placement="bottomRight"
                        onConfirm={() => _deletePaymentMethod(item.id)}
                        okText="Yes"
                        cancelText="No"
                        icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                      >
                        <Button 
                          danger 
                          shape="round" 
                          type="dashed" 
                          icon={<DeleteTwoTone twoToneColor="#ff7875"/>}
                        >
                          Delete 
                        </Button>
                      </Popconfirm>
                      ]
                  }>
                      <List.Item.Meta
                        title={item.cardholderName}
                        description={<>Card <Tag>XXXX XXXX XXXX <Text type="success" strong>{item.lastFourDigits || "XXXX"}</Text></Tag></>}
                      />
                  </List.Item>
                )}
              />
              <Button 
                shape="round"
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => setAddingPaymentMethod(true)} 
                disabled={loading} 
                style={{ marginBottom: 16 }}
              >
                Add Payment Method
              </Button>
            </>
        : <Elements stripe={stripePromise}>
            <CheckoutForm 
              organizationId={organizationId}
              onComplete={() => _getBilling()}
            />
          </Elements>
      : billing === null 
        ? <Alert message="There was an error fetching Billing Information." type="error" />
        : <br />
    }
  </Spin>
}

export default Billing;