import { FC, Key, useEffect, useState } from "react"
import { PermissionMutation, Role, Scope, Trail } from "nuaudit-browser-autogen"
import { DataNode } from 'rc-tree/lib/interface';
import { Button, Divider, List, message, Modal, Space, Spin, Tree, Typography } from "antd"
import { addPermissions, getRole, listTrails, removePermissions } from "../services/rest";
import { CheckSquareTwoTone, CloseSquareTwoTone } from "@ant-design/icons";
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;

interface PermissionsProps {
    organizationId: string,
    role: Role,
}

const Permissions: FC<PermissionsProps> = ({organizationId, role}) => {
    const [loading, setLoading] = useState(true);
    const [viewingChanges, setViewingChanges] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [trails, setTrails] = useState<Trail[]>();
    const [role_, setRole] = useState<Role>(role);
    const [selectedPermissions, setSelectedPermissions] = useState<PermissionMutation[]>(
        role_.permissions.map(permission => ({scope: permission.scope, entityId: permission.entityId}))
    )

    const hasChanges = selectedPermissions.find(permission => 
        !role_.permissions.find(role_permission =>
            role_permission.scope === permission.scope && 
            role_permission.entityId === permission.entityId
        )
    )

    const _getRole = async () => {
        setLoading(true)
        let _role: Role | null = null;
        try {
            _role = await getRole(organizationId, role_.id)
            setRole(_role)
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
        return _role
    }
    

    const discardChanges = (role: Role) => {
        setViewingChanges(false)
        setSelectedPermissions(role.permissions.map(permission => ({scope: permission.scope, entityId: permission.entityId})))
    }

    const permissionAdditions = () => selectedPermissions.filter(selectedPermission => 
        !role_.permissions.some(permission => 
            permission.entityId === selectedPermission.entityId &&
            permission.scope === selectedPermission.scope
        )
    )

    const permissionDeletions = () => role_.permissions.filter(permission => 
        !selectedPermissions.some(selectedPermission => 
            permission.entityId === selectedPermission.entityId &&
            permission.scope === selectedPermission.scope
        )
    )

    const saveChanges = async () => {
        setSavingChanges(true)
        const additions = permissionAdditions()
        const deletions = permissionDeletions()
        if (additions.length > 0) {
            await addPermissions(organizationId, role_.id, additions)
            message.success("Added Permissions to Role.")
        }
        if (deletions.length > 0) {
            await removePermissions(organizationId, role_.id, deletions.map(deletion => ({id: deletion.id})))
            message.success("Removed Permissions from Role.")
        }
        const role = await _getRole()
        role ? discardChanges(role) : discardChanges(role_)
        setSavingChanges(false)
    }

    const selectPermissions = (checked: Key[] | {
        checked: Key[];
        halfChecked: Key[];
    }) => {
        let keys: Key[] = []
        Array.isArray(checked) ? keys=checked : keys=checked.checked

        const permissions: PermissionMutation[] = keys
            .filter(key => (key as string).split(":").length === 3)
            .map(key => {
                const splitKey = (key as string).split(":")
                return ({
                    scope: `${splitKey[0]}:${splitKey[1]}` as Scope,
                    entityId: `${splitKey[2]}`
                })
            })
        setSelectedPermissions(permissions)
    };

    useEffect(() => {
        (async () => {
            let trails: Trail[] = [];
            try {
                if (!role_.isSystemRole) {
                    trails = await listTrails(organizationId)
                }
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setTrails(trails)
            setLoading(false)
        })()
    }, [organizationId, role_]);

    const scopeLabels = {
        "api_client:read": {
            label: "API Clients",
            accessLevel: "Read",
        },
        "api_client:write": {
            label: "API Clients",
            accessLevel: "Write",
        },
        "organization:read": {
            label: "Organization",
            accessLevel: "Read",
        },
        "organization:write": {
            label: "Organization",
            accessLevel: "Write",
        },
        "trail:read": {
            label: "Trails",
            accessLevel: "Read",
        },
        "trail:write": {
            label: "Trails",
            accessLevel: "Write",
        },
        "record:read": {
            label: "Records",
            accessLevel: "Read",
        },
        "record:write": {
            label: "Records",
            accessLevel: "Write",
        },
        "actor:read": {
            label: "Actors",
            accessLevel: "Read",
        },
        "resource:read": {
            label: "Resources",
            accessLevel: "Read",
        },
        "user:read": {
            label: "Users",
            accessLevel: "Read",
        },
        "role:read": {
            label: "Roles",
            accessLevel: "Read",
        },
        "role:write": {
            label: "Roles",
            accessLevel: "Write",
        },
        "invite:write": {
            label: "Invites",
            accessLevel: "Write",
        },
        "invite:read": {
            label: "Invites",
            accessLevel: "Read",
        },
        "usage:read": {
            label: "Usage",
            accessLevel: "Read",   
        },
        "billing:read": {
            label: "Billing",
            accessLevel: "Read",    
        },
        "billing:write": {
            label: "Billing",
            accessLevel: "Write",
        },
        "settings:read": {
            label: "Settings",
            accessLevel: "Read",    
        },
        "settings:write": {
            label: "Settings",
            accessLevel: "Write",
        }
    }

    const writeScopes = Object.entries(scopeLabels)
        .filter(entry => entry[1].accessLevel === "Write")
        .map(entry => `${entry[0]}:*`)

    const readScopes = Object.entries(scopeLabels)
        .filter(entry => entry[1].accessLevel === "Read")
        .map(entry => `${entry[0]}:*`)

    const treeData: DataNode[] = [
        {
            title:"Read",
            key:"read",
            disableCheckbox: true,
            children: Object.entries(scopeLabels)
                .filter(entry => entry[1].accessLevel === "Read")
                .map(entry => 
                    entry[1].label === "Records"
                        ? {
                            title: entry[1].label,
                            key:  `${entry[0]}:*`,
                            disableCheckbox: role_.isSystemRole,
                            children: !role_.isSystemRole 
                                ? trails?.map(trail =>
                                    ({
                                        title: trail.name,
                                        key: `record:read:${trail.id}`
                                    }))
                                : undefined
                        }
                        : {
                            title: entry[1].label,
                            key:  `${entry[0]}:*`,
                            disableCheckbox: role_.isSystemRole,
                        }
                )
        },
        {
            title:"Write",
            key:"write",
            disableCheckbox: true,
            children: Object.entries(scopeLabels)
                .filter(entry => entry[1].accessLevel === "Write")
                .map(entry => 
                    entry[1].label === "Records"
                        ? {
                            title: entry[1].label,
                            key:  `${entry[0]}:*`,
                            disableCheckbox: role_.isSystemRole,
                            children: !role_.isSystemRole 
                                ? trails?.map(trail =>
                                    ({
                                        title: trail.name,
                                        key: `record:write:${trail.id}`
                                    }))
                                : undefined
                        }
                        : {
                            title: entry[1].label,
                            key:  `${entry[0]}:*`,
                            disableCheckbox: role_.isSystemRole,
                        }
                )
        }
    ]

    const selectedScopes = selectedPermissions.map(permission => `${permission.scope}:${permission.entityId}`)
    const fullWrite = writeScopes.every(scope => selectedScopes.includes(scope)) ? ["write"] : []
    const fullRead = readScopes.every(scope => selectedScopes.includes(scope)) ? ["read"] : []

    const checkedKeys = () => {
        return selectedScopes.concat(fullWrite, fullRead)
    }

    return  <Spin spinning={loading}>
                <Tree 
                    checkable
                    checkStrictly={true}
                    selectable={false}
                    treeData={treeData}
                    checkedKeys={checkedKeys()}
                    defaultExpandedKeys={["read", "write"]}
                    onCheck={(checked) => selectPermissions(checked)}
                />
                {!role_.isSystemRole &&
                    <>
                        <Divider />
                        <Space wrap>
                            <Button size="small" shape="round" disabled={!hasChanges} onClick={() => setViewingChanges(true)} >View Permission Changes</Button>
                            <Button size="small" shape="round" disabled={!hasChanges} onClick={() => discardChanges(role_)}>Discard Permission Changes</Button>
                            <Button size="small" shape="round" disabled={!hasChanges} onClick={() => saveChanges()} type="primary" loading={savingChanges}>Save Permission Changes</Button>
                        </Space>
                    </>
                }
                <Modal 
                    title="Permission Changes" 
                    visible={viewingChanges}
                    confirmLoading={savingChanges}
                    onOk={() => saveChanges()} 
                    okText="Save Changes"
                    onCancel={() => setViewingChanges(false)}
                    cancelText="Close"
                >
                    {permissionAdditions().length > 0 && 
                    <>
                        <Divider orientation="left"><CheckSquareTwoTone twoToneColor="#52c41a"/> Additions</Divider>
                        <List
                            dataSource={permissionAdditions()}
                            size="small"
                            split={false}
                            renderItem={item => (
                                <List.Item>
                                    <Text strong>{scopeLabels[item.scope].accessLevel}</Text> {scopeLabels[item.scope].label}
                                </List.Item>
                            )}
                        >
                        </List>
                    </>}
                    {permissionDeletions().length > 0 && 
                    <>
                        <Divider orientation="left"><CloseSquareTwoTone twoToneColor="#eb2f96"/> Deletions</Divider>
                        <List
                            dataSource={permissionDeletions()}
                            size="small"
                            split={false}
                            renderItem={item => (
                                <List.Item>
                                    <Text strong>{scopeLabels[item.scope].accessLevel}</Text> {scopeLabels[item.scope].label}
                                </List.Item>
                            )}
                        ></List>
                    </>}
                </Modal>
            </Spin>
}

export default Permissions