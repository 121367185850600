import { FC } from "react"

const Nuaudit: FC = () => 
    <div style={{float:"left", width: "60px", height:"60px", paddingTop:"5px"}}>
        <svg style={{float:"left"}} width="50px" height="50px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path style={{fill: "#debfbf"}} d={`
                M 0,18 
                L 18,0 
                L 70,0
                C80 0 90 5 90 15
                L90 70
                C90 75 83 80 83 80
                L83 20
                C83 10 75 7 70 7
                L18 7
                L7 18
                L55 18
                C65 18 70 23 70 33
                L70 85
                C70 93 63 100 55 100
                L0 100
                Z`
            }/>
            <text x="7" y="40" fontWeight="bold" fontSize="20px" style={{fill: "#395973"}}>nu</text>
            <text x="7" y="55" fontWeight="bold" fontSize="20px" style={{fill: "#395973"}}>audit.</text>
        </svg>
    </div>

export default Nuaudit