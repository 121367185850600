import { DeleteTwoTone, ExclamationCircleTwoTone, PlusOutlined } from "@ant-design/icons"
import { Avatar, Button, Col, Form, Menu, message, PageHeader, Row, Space, Input, Popover, Popconfirm, Spin, Typography, Tabs } from "antd"
import { Organization, OrganizationMutation } from "nuaudit-browser-autogen"
import React, { FC, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import APIClients from "../components/APIClients"
import Roles from "../components/Roles"
import Users from "../components/Users"
import Usages from "../components/Usages"
import OrganizationProps from "../interfaces/OrganizationProps"
import { createOrganization, deleteOrganization, listOrganizations } from "../services/rest"
import formatInitials from "../utils/formatInitials"
import getErrorText from "../utils/getErrorText"
import Billing from "../components/Billing"

const {Text} = Typography;
const { TabPane } = Tabs;

const useQuery = () => {
return new URLSearchParams(useLocation().search);
}

const Organizations: FC<OrganizationProps> = ({organizationId, refresh}) => {
    const query = useQuery();
    const history = useHistory();

    const [form] = Form.useForm();

    const [organizations, setOrganizations] = useState<Organization[] | null>()
    const [viewingOrganization, setViewingOrganization] = useState<Organization>()
    const [createVisible, setCreateVisible] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)

    const handleCreateVisibleChange = (visible: boolean) => {
        form.resetFields()
        setCreateVisible(visible)
    }
    
    const onFinish = async (organizationMutation: OrganizationMutation) => {
        setSaving(true)
        try {
            const organization = await createOrganization(organizationMutation)
            await _getOrganizations()
            setViewingOrganization(organization)
            setCreateVisible(false)
            refresh()
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setSaving(false)
    }

    const onClick = async (organization: Organization) => {
        setViewingOrganization(organization)
    }

    const _getOrganizations = async () => {
        try {
            const organizations = await listOrganizations()
            setOrganizations(organizations)
            setViewingOrganization(organizations?.find(organization => organization.id === organizationId)) 
        } catch(error: any) {
            setOrganizations(null)
            if (error?.code !== 401) {
                message.error(getErrorText(error))
            }
        }
    }

    const _deleteOrganization = async (organization: Organization) => {
        setSaving(true)
        try {
            await deleteOrganization(organization.id)
            await _getOrganizations()
            setViewingOrganization(undefined)
            refresh()
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setSaving(false)
    }

    useEffect(() => {
        (async () => {
            await _getOrganizations()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return (
        <Row>
            <Col span={5}>
                <Menu style={{ minWidth: "100%" }} selectedKeys={[viewingOrganization?.id || organizationId]}>
                    {organizations?.map(organization =>
                        <Menu.Item key={organization.id} onClick={() => onClick(organization)}>
                            <Space wrap={true}><Avatar>{formatInitials(organization.name)}</Avatar>{organization.name}</Space>
                        </Menu.Item>
                    )}
                </Menu>
                <Popover 
                    visible={createVisible}
                    onVisibleChange={handleCreateVisibleChange}
                    trigger="click"
                    placement="bottomLeft"
                    content={
                        <Space direction="vertical">
                            <Form layout="vertical" requiredMark={false} onFinish={onFinish} form={form}>
                                <Form.Item
                                label="Create an Organization"
                                name="name"
                                rules={[{ required: true, message: 'Required.' }]}
                                >
                                    <Input placeholder="Organization Name"  autoComplete="off" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={saving}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Space>
                    }>
                    <Button shape="round" type="dashed" icon={<PlusOutlined />} disabled={saving}>New</Button>
                </Popover>
            </Col>
            <Col span={1} />
            <Col span={18}>
                {viewingOrganization 
                    ? 
                        <Spin spinning={saving}>
                            <PageHeader 
                                title={viewingOrganization.name}
                                subTitle={viewingOrganization.id} 
                                extra={[
                                    <Popconfirm
                                        title={<>
                                            <Text strong>Are you sure to delete this Organization?</Text>
                                            <br/>
                                            <Text type="danger">This action will delete trails and records.</Text>
                                        </>}
                                        placement="bottomRight"
                                        onConfirm={() => _deleteOrganization(viewingOrganization)}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                                    >
                                        <Button danger shape="round" type="dashed" icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>Delete Organization</Button>
                                    </Popconfirm>
                                ]}
                            >
                                <Tabs type="card" defaultActiveKey={query.get("tab") ?? undefined} onChange={(activeKey) => history.replace({search: `?tab=${activeKey}`})}>
                                    <TabPane tab="Users" key="users">
                                        <Users organizationId={viewingOrganization.id} />
                                    </TabPane>
                                    <TabPane tab="API Clients" key="api_clients">
                                        <APIClients organizationId={viewingOrganization.id} />
                                    </TabPane>
                                    <TabPane tab="Roles" key="roles">
                                        <Roles organizationId={viewingOrganization.id} />
                                    </TabPane>
                                    <TabPane tab="Usage" key="usage">
                                        <Usages organizationId={viewingOrganization.id} />
                                    </TabPane>
                                    <TabPane tab="Billing" key="billing">
                                        <Billing organizationId={viewingOrganization.id} />
                                    </TabPane>
                                </Tabs>
                            </PageHeader>                  
                        </Spin>
                    :   <Row justify="center" align="middle">
                            <Col>
                                <Spin />
                            </Col>
                        </Row>
                }
            </Col>
        </Row>
    )
}

export default Organizations