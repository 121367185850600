import React, { FC } from "react"
import { Avatar, Card, Col, Layout, List, Row} from "antd"
import { Organization } from "nuaudit-browser-autogen";
import formatInitials from "./utils/formatInitials";
import { Link } from "react-router-dom";

const { Content } = Layout;

interface SelectOrganizationProps {
    organizations: Organization[]
    setSelectedOrganization: (organization: Organization) => void
}

const SelectOrganization: FC<SelectOrganizationProps> = ({organizations, setSelectedOrganization}) => {
    return <Layout>
        <Content>
            <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                <Col>
                    <Card title="nuaudit.">
                        <List 
                            dataSource={organizations}
                            renderItem={organization => (
                                <List.Item style={{minWidth:"20vw"}}>
                                    <List.Item.Meta
                                        avatar={<Avatar>{formatInitials(organization.name)}</Avatar>}
                                        title={<Link to={"/"} onClick={() => setSelectedOrganization(organization)}>{organization.name}</Link>}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>
        </Content>
    </Layout>}

export default SelectOrganization;

