import { MailOutlined } from "@ant-design/icons"
import { Button, Layout, Result, Space } from "antd"
import React, { FC } from "react"
import { Link } from "react-router-dom"

import Nuaudit from '../components/Nuaudit';

const { Header, Content, Footer } = Layout;

const UnverifiedEmail: FC = () => 
    <Layout style={{minHeight:"100%"}}>
        <Header>
            <Nuaudit />
        </Header>
        <Content style={{ padding: '20px 20px 0px 20px', minHeight: "100%", display: "flex", flexFlow: "column" }}>
            <Result
                icon={<MailOutlined />}
                title="Great, please check your email to verify your login!"
                extra={<Space wrap>
                    <Link to={"/"}><Button>Home</Button></Link>
                    <Button onClick={() => window.location.assign("/api/logout")} type="primary">Logout</Button>
                </Space>}
            />
        </Content>
        <Footer style={{ textAlign: 'center' }}>nuaudit ©2021</Footer>
    </Layout>

export default UnverifiedEmail