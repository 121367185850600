import { Descriptions, message, PageHeader, Spin, Table, Typography } from "antd";
import React, { FC, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import OrganizationProps from "../interfaces/OrganizationProps";
import {Resource as ResourceType, Record, ActorRecord} from "nuaudit-browser-autogen"
import { getResource, listResourceRecords } from "../services/rest";
import { format } from 'date-fns'
import DateCol from "../components/DateCol";
import ActorRecordCol from "../components/ActorRecordCol";
import htmlDiff from "../utils/htmlDiff";
import getErrorText from "../utils/getErrorText";

const { Text, Title } = Typography;

const Resource: FC<OrganizationProps> = ({organizationId}) => {
    const { id } = useParams<{id: string}>();

    const history = useHistory();

    const [resource, setResource] = useState<ResourceType | null>()
    const [records, setRecords] = useState<Record[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let resource: ResourceType | null = null;
            let records: Record[] = []

            try {
                resource = await getResource(organizationId, id)
                records = await listResourceRecords(organizationId, id)
            } catch(error: any) {
                message.error(getErrorText(error))
                if (error?.code === 404) {
                    history.replace("/resources")
                }
            }
            setResource(resource)
            setRecords(records)
            setLoading(false)
        })()
    }, [organizationId, history, id])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => <DateCol date={createdAt} />
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Actor',
            dataIndex: 'ActorRecord',
            key: 'actorRecord',
            render: (_: ActorRecord, record: Record) => <ActorRecordCol record={record}/>
        }
    ];

    return (
        <Spin spinning={loading}>
            <PageHeader title={resource?.metadata.derivedId} subTitle={resource?.metadata.derivedId} onBack={() => window.history.back()}>
                <Descriptions column={1} bordered={true}>
                    {resource && <Descriptions.Item label="Last Updated At"><Text>{format(new Date(resource.lastUpdatedAt), "pppp")}</Text></Descriptions.Item>}
                    {records.length && <Descriptions.Item label="Latest Data">
                        <div dangerouslySetInnerHTML={{ __html: htmlDiff({}, records[0].resourceRecord.data )}}/>
                    </Descriptions.Item>}
                </Descriptions>
            </PageHeader>
            <Table title={() => <Title level={4}>Records</Title>} dataSource={records} columns={columns} pagination={{position: ["bottomLeft"]}} />
        </Spin>
    )}

export default Resource
