import { DeleteTwoTone, EditTwoTone, ExclamationCircleTwoTone, PlusOutlined, SaveTwoTone, StopTwoTone } from "@ant-design/icons";
import { Button, Form, Input, message, Popconfirm, Space, Table, Typography } from "antd";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { Role } from "nuaudit-browser-autogen";
import React, { FC, useEffect, useState } from "react"
import { createRole, listRoles, updateRole, deleteRole } from "../services/rest";

import Permissions from './Permissions';
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;

interface RolesProps {
    organizationId: string
}

const Roles: FC<RolesProps> = ({organizationId}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const [roles, setRoles] = useState<Role[]>([]);
    const [editingId, setEditingId] = useState<string | undefined | null>(null);

    const setEditing = (id: string) => setEditingId(id);
    const setCreating = () => setEditingId(undefined)
    const clearEditing = () => setEditingId(null);
    const isEditing = (role?: Role) => role ? role.id === editingId : editingId !== null;
    const isCreating = () => editingId === undefined;

    const add = () => {
        form.resetFields()
        const rolesCopy = [{}, ...roles] as Role[];
        setRoles(rolesCopy)
        setCreating()
    }

    const del = async (role: Role) => {
        setLoading(true)
        try {
            await deleteRole(organizationId, role.id)
            const rolesCopy = roles.filter(r => r.id !== role.id)
            setRoles(rolesCopy)
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    };

    const edit = (role: Role) => {
        form.setFieldsValue(role);
        role.id && setEditing(role.id);
    };

    const cancel = () => {
        isCreating() && setRoles(roles.slice(1))
        form.resetFields()
        clearEditing();
    };

    const save = async () => {
        setLoading(true)
        try {
            let row = (await form.validateFields()) as Role;

            const rolesCopy = [...roles];
            const idx = rolesCopy.findIndex(role => row.id === role.id);
            if (idx > -1) {
                let client = rolesCopy[idx];

                if (row.id === undefined) {
                    const newRow = await createRole(organizationId, row);
                    if (newRow) {
                        row = newRow
                    } else {
                        return
                    }
                } else {
                    await updateRole(organizationId, row.id ,row);
                }

                rolesCopy.splice(idx, 1, {
                    ...client,
                    ...row
                })
                setRoles(rolesCopy)
                form.resetFields()
                clearEditing()
            } else {
                message.error("Something went wrong.")
            }
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    };

    useEffect(() => {
        (async () => {
            setLoading(true)
            let roles: Role[] = [];
            try {
                roles = await listRoles(organizationId)
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setRoles(roles)
            setLoading(false)
        })()
    }, [organizationId]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_: any, record: Role) => isEditing(record)
                ?   <>
                        <Form.Item name={"id"} hidden>{record.id}</Form.Item>
                        <Form.Item name={"name"} noStyle={true}><Input placeholder="Role Name" /></Form.Item>
                    </>
                :   <>
                        <Text strong>{record.name}</Text>
                        <br />
                        <Text type="secondary">{record.isSystemRole ? "System Role" : "Custom Role"}</Text>
                    </>
        },
        {
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: Role) => 
                record.isSystemRole
                ?   <></>
                :   isEditing(record) 
                    ? <Space style={{float: "right"}} wrap>
                          <Button shape="round" icon={<SaveTwoTone />} onClick={()=> save()} >Save</Button>
                          <Button danger type="primary" shape="round" icon={<StopTwoTone twoToneColor="#ff7875"/>} onClick={()=> cancel()} >Cancel</Button>
                      </Space>
                    : <Space style={{float: "right"}} wrap>
                        <Button disabled={!!editingId || isEditing()} shape="round" icon={<EditTwoTone />} onClick={()=> edit(record)} >Edit Role Name</Button>
                        <Popconfirm
                            title={<>
                                <Text strong>Are you sure to delete this Role?</Text>
                                <br/>
                                <Text type="danger">This role will be removed from all associated Users and API Clients.</Text>
                            </>}
                            placement="bottomRight"
                            onConfirm={() => del(record)}
                            okText="Yes"
                            cancelText="No"
                            icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                        >
                            <Button danger shape="round" type="dashed"  icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>Delete</Button>
                        </Popconfirm>
                    </Space>
                    
        },
    ]

    return <>
        <Form form={form} component={false} >
            <Button shape="round" icon={<PlusOutlined />} onClick={() => add()} style={{ marginBottom: 16 }}>Role</Button>
            <Table
                components={{
                    body: {
                        row: undefined
                    }
                }}
                showHeader={false}
                pagination={false}
                dataSource={roles.map(role => ({key: role.id, ...role}))}
                loading={loading}
                columns={columns}
                expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                        <MinusCircleTwoTone twoToneColor="#B56357" onClick={e => onExpand(record, e)} />
                        ) : (
                        <PlusCircleTwoTone twoToneColor="#B56357" onClick={e => onExpand(record, e)} />
                        ),
                    rowExpandable: role => !!role.id,
                    expandedRowRender: role => <Permissions organizationId={organizationId} role={role} />,
                }}
            />
        </Form>
    </>
}

export default Roles