import React from "react"
import { Col, Layout, Row, Spin } from "antd"

const { Content } = Layout;

const Loading = () => 
    <Layout>
        <Content>
            <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                <Col>
                    <Spin tip="Loading..." />
                </Col>
            </Row>
        </Content>
    </Layout>

export default Loading;