import { DeleteTwoTone, EditTwoTone, ExclamationCircleTwoTone, PlusOutlined, SaveTwoTone, StopTwoTone } from "@ant-design/icons";
import { Button, Form, Input, message, Space, Typography, Table, Popconfirm, Drawer, Spin, Checkbox, Divider } from "antd";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { APIClient, Role } from "nuaudit-browser-autogen";
import React, { FC, useEffect, useState } from "react";
import { addApiClientToRole, createApiClient, deleteApiClient, listApiClients, listRoles, listRolesForApiClient, removeApiClientFromRole, updateApiClient } from "../services/rest";
import APIKeys from "./APIKeys";
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;

interface APIClientsProps {
    organizationId: string
}

const APIClients: FC<APIClientsProps> = ({organizationId}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const [apiClients, setApiClients] = useState<APIClient[]>([]);
    const [editingId, setEditingId] = useState<string | undefined | null>(null);
    //Roles
    const [roles, setRoles] = useState<Role[]>();
    const [apiClientRoles, setApiClientRoles] = useState<Role[]>();
    const [selectedApiClientRoles, setSelectedApiClientRoles] = useState<Role[]>();
    const [hasChanges, setHasChanges] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [editVisibile, setEditVisible] = useState<boolean>(false);
    const [editLoading, setEditLoading] = useState<boolean>(true);

    const setEditing = (id: string) => setEditingId(id);
    const setCreating = () => setEditingId(undefined)
    const clearEditing = () => setEditingId(null);
    const isEditing = (apiClient?: APIClient) => apiClient ? apiClient.id === editingId : editingId !== null;
    const isCreating = () => editingId === undefined;

    const discardChanges = () => {
        setHasChanges(false)
        setSelectedApiClientRoles(apiClientRoles)
    }

    const selectRole = async (role: Role, checked: boolean) => {
        setHasChanges(true)
        if (checked) {
            setSelectedApiClientRoles(selectedApiClientRoles?.concat(role))
        } else {
            setSelectedApiClientRoles(selectedApiClientRoles?.filter(apiClientRole => apiClientRole.id !== role.id))
        }
    }

    const roleAdditions = () => selectedApiClientRoles?.filter(selectedApiClientRole => 
        !apiClientRoles?.some(apiClientRole => apiClientRole.id === selectedApiClientRole.id)
    )

    const roleDeletions = () => apiClientRoles?.filter(apiClientRole => 
        !selectedApiClientRoles?.some(selectedApiClientRole => apiClientRole.id === selectedApiClientRole.id)
    )

    const saveRoleChanges = async (apiClient: APIClient) => {
        setSavingChanges(true)
        const additions = roleAdditions()
        const deletions = roleDeletions()
        if (additions && additions.length > 0) {
            for (const role of additions) {
                try {
                    await addApiClientToRole(organizationId, role.id, apiClient.id)
                    message.success(`Added API Client ${apiClient.name} to Role ${role.name}.`)
                } catch(error: any) {
                    message.error(getErrorText(error))
                }
            }
        }
        if (deletions && deletions?.length > 0) {
            for (const role of deletions) {
                try {
                    await removeApiClientFromRole(organizationId, role.id, apiClient.id)
                    message.success(`Removed API Client ${apiClient.name} from Role ${role.name}.`)
                } catch(error: any) {
                    message.error(getErrorText(error))
                }
            }
        }
        setApiClientRoles(selectedApiClientRoles)
        setHasChanges(false)
        setSavingChanges(false)
    }

    const editRoles = async (organizationId: string, apiClientId: string) => {
        setEditLoading(true)
        setEditVisible(true)
        const [roles, apiClientRoles] = await Promise.all([listRoles(organizationId), listRolesForApiClient(organizationId, apiClientId)])
        setRoles(roles)
        setApiClientRoles(apiClientRoles)
        setSelectedApiClientRoles(apiClientRoles)
        setEditLoading(false)
    }

    const edit = (apiClient: APIClient) => {
        form.setFieldsValue(apiClient);
        apiClient.id && setEditing(apiClient.id);
    };

    const cancel = () => {
        isCreating() && setApiClients(apiClients.slice(1))
        form.resetFields()
        clearEditing();
    };

    const save = async () => {
        setLoading(true)
        try {
            let row = (await form.validateFields()) as APIClient;

            const apiClientsCopy = [...apiClients];
            const idx = apiClientsCopy.findIndex(apiClient => row.id === apiClient.id);
            if (idx > -1) {
                let client = apiClientsCopy[idx];

                if (row.id === undefined) {
                    const newRow = await createApiClient(organizationId, row);
                    if (newRow) {
                        row = newRow
                    } else {
                        return
                    }
                } else {
                    await updateApiClient(organizationId, row.id ,row);
                }

                apiClientsCopy.splice(idx, 1, {
                    ...client,
                    ...row
                })
                setApiClients(apiClientsCopy)
                form.resetFields()
                clearEditing()
            } else {
                message.error("Something went wrong.")
            }
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    };

    const add = () => {
        form.resetFields()
        const apiClientsCopy = [{}, ...apiClients] as APIClient[];
        setApiClients(apiClientsCopy)
        setCreating()
    }

    const _deleteApiClient = async (record: APIClient) => {
        setLoading(true)
        try {
            await deleteApiClient(organizationId, record.id)
            setApiClients(apiClients.filter(apiClient =>  apiClient.id !== record.id))
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    }
    
    useEffect(() => {
        (async () => {
            setLoading(true)
            let clients: APIClient[] = [];
            try {
                clients = await listApiClients(organizationId)
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setApiClients(clients);
            setLoading(false)
        })()
    }, [organizationId]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_: any, record: APIClient) => isEditing(record) 
                ?   <>
                        <Form.Item name={"name"} noStyle={true}><Input placeholder="API Client Name" /></Form.Item>
                        <Form.Item name={"id"} hidden />
                    </>
                :   <>
                        <Text>{record.name}</Text>
                        <br />
                        <Text type="secondary">{record.id}</Text>
                    </>
        },
        {
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: APIClient) => 
                isEditing(record) 
                    ?   <Space wrap style={{float: "right"}}>
                            <Button shape="round" icon={<SaveTwoTone />} onClick={()=> save()} >Save</Button>
                            <Button danger type="primary" shape="round" icon={<StopTwoTone twoToneColor="#ff7875"/>} onClick={()=> cancel()} >Cancel</Button>
                        </Space>
                    :   <Space wrap style={{float: "right"}}>
                            <Button disabled={!!editingId || isEditing()} shape="round" icon={<EditTwoTone />} onClick={() => editRoles(organizationId, record.id)}>Edit Roles</Button>
                            <Button disabled={!!editingId || isEditing()} shape="round" icon={<EditTwoTone />} onClick={()=> edit(record)} >Edit</Button>
                            <Popconfirm
                                title="Are you sure to delete this API Client?"
                                onConfirm={() => _deleteApiClient(record)}
                                okText="Yes"
                                cancelText="No"
                                icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                            >
                                <Button danger shape="round" type="dashed" icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>Delete API Client</Button>
                            </Popconfirm>
                            <Drawer
                            title={"Roles"}
                            placement={"right"}
                            visible={editVisibile}
                            onClose={() => setEditVisible(false)}
                            width={384}
                            >
                                <Spin spinning={editLoading}>
                                    {
                                        apiClientRoles !== undefined && (roles
                                            ?   roles.map(role => 
                                                    <>
                                                        <Checkbox checked={selectedApiClientRoles?.some(apiClientRole => apiClientRole.id === role.id)} onChange={(e) => selectRole(role, e.target?.checked)}>
                                                            {role.name} {role.isSystemRole && <Text type="secondary">System Role</Text>}
                                                        </Checkbox>
                                                        <br />
                                                    </>
                                                )
                                            :   <Text>You have no Roles.</Text>)
                                            
                                    }
                                    <Divider />
                                    <Space direction={"vertical"}>
                                        <Button shape="round" block={true} disabled={!hasChanges || savingChanges} onClick={() => discardChanges()}>Discard Role Changes</Button>
                                        <Button shape="round" block={true} disabled={!hasChanges} onClick={() => saveRoleChanges(record)} type="primary" loading={savingChanges}>Save Role Changes</Button>
                                    </Space>
                                </Spin>
                            </Drawer>
                    </Space> 
        },
    ];
    
    return <>
        <Form form={form} component={false} >
            <Button shape="round" icon={<PlusOutlined />} onClick={() => add()} disabled={loading || isEditing()} style={{ marginBottom: 16 }}>API Client</Button>
            <Table
                showHeader={false}
                pagination={false}
                loading={loading}
                dataSource={apiClients.map(apiClient => ({key: apiClient.id, ...apiClient}))}
                columns={columns}
                expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                        <MinusCircleTwoTone twoToneColor="#B56357" onClick={e => onExpand(record, e)} />
                        ) : (
                        <PlusCircleTwoTone twoToneColor="#B56357" onClick={e => onExpand(record, e)} />
                        ),
                    rowExpandable: apiClient => !!apiClient.id,
                    expandedRowRender: apiClient => <APIKeys organizationId={organizationId} apiClientId={apiClient.id} />
                }}
            />
        </Form>
    </>                                  
}

export default APIClients