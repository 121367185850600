import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import './App.less';

import UnverifiedEmail from './pages/UnverifiedEmail';

import Main from './Main'

const App: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/unverified_email">
          <UnverifiedEmail />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}


export default App;