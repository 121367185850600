import { DeleteTwoTone, EditTwoTone, ExclamationCircleTwoTone, PlusOutlined, SaveTwoTone, StopTwoTone } from "@ant-design/icons";
import { Button, Descriptions, Divider, Form, List, message, Popconfirm, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { APIKey } from "nuaudit-browser-autogen";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createApiKey, deleteApiKey, listApiKeys, updateApiKey } from "../services/rest";
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;

interface APIKeysProps {
    organizationId: string
    apiClientId: string
}

const APIKeys: FC<APIKeysProps> = ({organizationId, apiClientId}) => {
    const history = useHistory();

    const [apiKeyForm] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [apiKeys, setApiKeys] = useState<APIKey[]>([]);
    const [editingApiKeyId, setEditingApiKeyId] = useState<string | undefined | null>(null);

    const setEditingApiKey = (id: string) => setEditingApiKeyId(id);
    const setCreatingApiKey = () => setEditingApiKeyId(undefined)
    const clearEditingApiKey = () => setEditingApiKeyId(null);
    const isEditingApiKey = (record?: APIKey) => record ? record.id === editingApiKeyId : editingApiKeyId !== null;
    const isCreatingApiKey = () => editingApiKeyId === undefined;

    const editApiKey = (record: APIKey) => {
        apiKeyForm.setFieldsValue(record);
        record.id && setEditingApiKey(record.id);
    };

    const cancelApiKey = () => {
        isCreatingApiKey() && setApiKeys(apiKeys.slice(1))
        apiKeyForm.resetFields()
        clearEditingApiKey();
    };

    const saveApiKey = async () => {
        setLoading(true)
        try {
            let row = (await apiKeyForm.validateFields()) as APIKey;

            const apiKeysCopy = [...apiKeys];
            const idx = apiKeysCopy.findIndex(apiKey => row.id === apiKey.id);
            if (idx > -1) {
                let apiKey = apiKeysCopy[idx];

                if (row.id === undefined) {
                    const newRow = await createApiKey(organizationId, apiClientId, row);
                    if (newRow) {
                        row = newRow
                    } else {
                        return
                    }
                } else {
                    await updateApiKey(organizationId, apiClientId, row.id ,row);
                }

                apiKeysCopy.splice(idx, 1, {
                    ...apiKey,
                    ...row
                })
                setApiKeys(apiKeysCopy)
                apiKeyForm.resetFields()
                clearEditingApiKey()
            } else {
                message.error("Something went wrong.")
            }
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    };

    const addApiKey = () => {
        apiKeyForm.resetFields()
        const apiKeysCopy = [{}, ...apiKeys] as APIKey[];
        setApiKeys(apiKeysCopy)
        setCreatingApiKey()
    }

    const _deleteApiKey = async (record: APIKey) => {
        setLoading(true)
        try {
            await deleteApiKey(organizationId, apiClientId, record.id)
            setApiKeys(apiKeys.filter(apiKey =>  apiKey.id !== record.id))
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        message.success("Deleted API Key.")
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            let apiKeys: APIKey[] = []
            try {
                apiKeys = await listApiKeys(organizationId, apiClientId)
            } catch(error: any) {
                message.error(error?.body?.detail || error)
                if (error?.code === 404) {
                    history.replace("/organizations?tab=api_clients")
                }
            }
            setApiKeys(apiKeys)
            setLoading(false)
        })()
    },[organizationId, apiClientId, history])
    
    return <Form form={apiKeyForm} component={false} >
        <Button size="small" shape="round" type="dashed" icon={<PlusOutlined />} onClick={() => addApiKey()} disabled={loading || isEditingApiKey()} style={{ marginBottom: 16 }}>Create API Key</Button>
        <List loading={loading} dataSource={apiKeys} renderItem={(apiKey, index) => 
            isEditingApiKey(apiKey) 
            ?   <>
                    <Form.Item name={"id"} hidden>{apiKey.id}</Form.Item>
                    <Form.Item name={"description"}><TextArea placeholder="Description" autoSize/></Form.Item> 
                    <Space wrap>
                        <Button size="small" shape="round" icon={<SaveTwoTone />} onClick={()=> saveApiKey()} >Save</Button>
                        <Button size="small" danger type="primary" shape="round" icon={<StopTwoTone twoToneColor="#ff7875"/>} onClick={()=> cancelApiKey()} >Cancel</Button>
                    </Space>
                    {index !== apiKeys.length -1 && <Divider />}
                </>       
            :   <>   
                    <Descriptions title={apiKey.description} column={1}>
                        <Descriptions.Item label="ID"><Text code>{apiKey.id}</Text></Descriptions.Item>
                        <Descriptions.Item label="Secret"><Text code>{apiKey.secret}</Text></Descriptions.Item>
                    </Descriptions>
                    <Space wrap>
                        <Button size="small" disabled={!!editingApiKeyId || isEditingApiKey()} shape="round" icon={<EditTwoTone />} onClick={()=> editApiKey(apiKey)} >Edit</Button>
                        <Popconfirm
                            title="Are you sure to delete this API Key?"
                            onConfirm={() => _deleteApiKey(apiKey)}
                            okText="Yes"
                            cancelText="No"
                            icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                        >
                            <Button danger size="small" shape="round" type="dashed" icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>Delete API key</Button>
                        </Popconfirm>
                    </Space>
                    {index !== apiKeys.length -1 && <Divider />}
                </>    
        }/>
    </Form>
}

export default APIKeys