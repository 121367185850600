import { message, PageHeader, Statistic, Table, Typography } from "antd"
import { Actor, Metadata } from "nuaudit-browser-autogen";
import React, { FC, useEffect, useState } from "react"
import OrganizationProps from "../interfaces/OrganizationProps"
import { listActors } from "../services/rest";
import { Link } from "react-router-dom";
import DateCol from "../components/DateCol";
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;

const Actors: FC<OrganizationProps> =  ({organizationId}) => {
    const [actors, setActors] = useState<Actor[]>();

    useEffect(()=> {
        (async () => {
            let actors: Actor[] = []
            try {
                actors = await listActors(organizationId)
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setActors(actors)
        })()
    }, [organizationId])

    const columns = [
        {
            title: 'Last Seen',
            dataIndex: 'lastSeenAt',
            key: 'lastSeenAt',
            render: (lastSeenAt: string) => <DateCol date={lastSeenAt} />
        },
        {
            title: 'Actor',
            dataIndex: 'metadata',
            key: 'metadata',
            render: (metadata: Metadata, actor: Actor) => <>
                <Link to={`/actors/${actor.id}`}>{metadata.derivedId}</Link>
                <br />
                <Text type="secondary">{metadata.derivedType}</Text>
            </>
        },
        {
            title: 'Total Records',
            dataIndex: 'totalRecords',
            key: 'totalRecords',
            render: (totalRecords: string) => <Statistic value={totalRecords} />
        },
        {
            title: 'Trail',
            dataIndex: 'trailId',
            key: 'trailId',
            render: (trailId: string) => <Link to={`/trails/${trailId}`}>{trailId}</Link>
        }
    ]
    return (
        <PageHeader title="Actors" onBack={() => window.history.back()}>
            <Table loading={actors === undefined} columns={columns} dataSource={actors} pagination={{position: ["bottomLeft"]}} />
        </PageHeader>
    )
}

export default Actors