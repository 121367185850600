import initials from 'initials';

const formatInitials = (word: string): string => {
    let wordInitials = initials(word) as string;
    if (wordInitials.length > 2) {
      wordInitials = wordInitials.slice(0, 1) + wordInitials.slice(-1);
    }
    return wordInitials.toUpperCase();
  };

export default formatInitials;