import { FC } from "react"
import { format, formatDistanceToNow } from 'date-fns'
import { Typography } from "antd"

const { Text } = Typography;

interface DateColProps {
    date: string
}

const DateCol: FC<DateColProps> = ({date}) =>
    <>
        <Text>{format(new Date(date), "pppp")}</Text>
        <br />
        <Text type="secondary">{formatDistanceToNow(new Date(date), { addSuffix: true })}</Text>
    </>

export default DateCol