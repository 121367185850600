import React, { FC, useEffect, useState } from "react";
import { Form, Table, Input, Space, Button, message, Typography, PageHeader, Alert } from "antd";
import { EditTwoTone, SaveTwoTone, StopTwoTone, PlusOutlined } from '@ant-design/icons';
import { Trail } from 'nuaudit-browser-autogen';
import { Link } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import OrganizationProps from "../interfaces/OrganizationProps";
import { createRecord, createTrail, listTrails, updateTrail } from "../services/rest";
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;
const { TextArea } = Input;


const Trails: FC<OrganizationProps> = ({organizationId}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const [trails, setTrails] = useState<Trail[]>([]);
    const [editingId, setEditingId] = useState<string | undefined | null>(null);

    const setEditing = (id: string) => setEditingId(id);
    const setCreating = () => setEditingId(undefined)
    const clearEditing = () => setEditingId(null);

    const isEditing = (record?: Trail) => record ? record.id === editingId : editingId !== null;
    const isCreating = () => editingId === undefined;
    const isNew = () => !loading && (trails.length === 0 || (trails.length === 1 && isCreating()))

    useEffect(() => {
        (async () => {
            setLoading(true)
            clearEditing()
            await _listTrails()
            setLoading(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const _listTrails = async () => {
        let trails: Trail[] = []
        try {
            trails = await listTrails(organizationId)
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setTrails(trails);
    }

    const edit = (record: Trail) => {
        form.setFieldsValue(record);
        record.id && setEditing(record.id);
    };

    const cancel = () => {
        isCreating() && setTrails(trails.slice(1))
        form.resetFields()
        clearEditing();
    };

    const save = async () => {
        setLoading(true)
        try {
            let row = (await form.validateFields()) as Trail;

            const trailsCopy = [...trails];
            const idx = trailsCopy.findIndex(trail => row.id === trail.id);
            if (idx > -1) {
                let trail = trailsCopy[idx];

                if (row.id === undefined) {
                    const newRow = await createTrail(organizationId, row);
                    if (newRow) {
                        row = newRow
                    } else {
                        return
                    }
                } else {
                    await updateTrail(organizationId, row.id ,row);
                }

                trailsCopy.splice(idx, 1, {
                    ...trail,
                    ...row
                })
                setTrails(trailsCopy)
                form.resetFields()
                clearEditing()
            } else {
                message.error("Something went wrong.")
            }
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    };

    const add = () => {
        form.resetFields()
        const trailsCopy = [{}, ...trails] as Trail[];
        setTrails(trailsCopy)
        setCreating()
    }

    const createExampleTrail = async () => {
        setLoading(true)
        const trail = await createTrail(organizationId, {
            name: "Example Trail",
            description: "This is an example trail with three records"
        })
        await createRecord(organizationId, trail.id, {
            actorRecord: {
                data: {
                    "type": "human",
                    "id": "jane",
                    "name": "Jane",
                    "email": "jane@example.org",
                    "ipAddress": "127.0.0.1"
                }
            },
            resourceRecord: {
                data: {
                    "type": "artwork",
                    "id": "thepainting",
                    "title": "The painting",
                    "material": "canvas",
                    "paint": "oil",
                    "year": 2018
                }
            },
            description: "Added artwork to the gallery"
        })
        await createRecord(organizationId, trail.id, {
            actorRecord: {
                data: {
                    "type": "human",
                    "id": "frederick",
                    "name": "Frederick",
                    "preferredName": "Fred",
                    "email": "fred@example.org",
                }
            },
            resourceRecord: {
                data: {
                    "type": "artwork",
                    "id": "thesculpture",
                    "title": "The sculpture",
                    "material": "marble",
                    "year": 1019,
                    "kilograms": "150"
                }
            },
            description: "Added artwork to the gallery"
        })
        await createRecord(organizationId, trail.id, {
            actorRecord: {
                data: {
                    "type": "human",
                    "id": "jane",
                    "name": "Jane",
                    "email": "jane@example.org",
                    "ipAddress": "127.0.0.2"
                }
            },
            resourceRecord: {
                data: {
                    "type": "artwork",
                    "id": "thesculpture",
                    "title": "The sculpture",
                    "material": "marble",
                    "year": 2019,
                    "kilograms": "160"
                }
            },
            description: "Updated artwork"
        })
        await _listTrails()
        message.success("Created example Trail.")
        setLoading(false)
    }

    const columns = [
        {
            title: 'Last Record',
            dataIndex: 'lastRecordAt',
            key: 'lastRecordAt',
            render: (text: string) => text
                ?   <Text type="secondary">{formatDistanceToNow(new Date(text), { addSuffix: true })}</Text>
                :   <Text type="secondary">No Records</Text>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_: any, record: Trail) => isEditing(record) 
                ?   <>
                        <Form.Item name={"id"} hidden>{record.id}</Form.Item>
                        <Form.Item name={"name"} noStyle={true}><Input placeholder="Trail Name" /></Form.Item>
                    </>
                :   <Link to={`/trails/${record.id}`}>{record.name}</Link>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (_: any, record: Trail) => isEditing(record)
                ?   <Form.Item name={"description"} noStyle={true}><TextArea placeholder="Description" autoSize/></Form.Item>
                :   <>{record.description}</>
        },
        {
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: Trail) => isEditing(record) 
                ?   <Space wrap style={{float: "right"}}>
                        <Button shape="round" icon={<SaveTwoTone />} onClick={()=> save()} >Save</Button>
                        <Button danger type="primary" shape="round" icon={<StopTwoTone twoToneColor="#ff7875"/>} onClick={()=> cancel()} >Cancel</Button>
                    </Space>
                : <Button disabled={!!editingId || isEditing()} shape="round" icon={<EditTwoTone />} onClick={()=> edit(record)} >Edit</Button>
        },
    ];


    return  (
        <>
            { isNew() && 
                <Alert 
                    showIcon 
                    type="info" 
                    action={
                        <Button loading={loading} shape="round" type="primary" onClick={() => createExampleTrail()}>
                          Create Example Trail and Records
                        </Button>
                      }
                    message={<Text strong>It looks like this organization has no trails.</Text>}
                    description="Would you like to create an example trail with three example records?"
                />
            }
            <PageHeader title="Trails" 
            extra={[
                <Button shape="round" icon={<PlusOutlined />} onClick={() => add()} disabled={loading || isEditing()}>Create Trail</Button>
            ]}>
                <Form form={form} component={false} >
                    <Table loading={loading} dataSource={trails} columns={columns} pagination={{position: ["bottomLeft"]}} />
                </Form>
            </PageHeader>
        </>
    )
}

export default Trails;


