const getErrorText = (error: any) => {
    console.error(error)

    const detail = error?.body?.detail;
    // If detail is a string, return immediately
    if(typeof detail === 'string') {
        return detail;
    }

    // If detail is an array, and have msg due to HTTP 422, return all msgs.
    if (Array.isArray(detail)) {
        return detail.reduce((p, c) => c.msg ? (p + `${c.msg}.\n`) : p, "") 
    }

    try {
        // Is error JSON serialisable?
        return JSON.parse(error);
    } catch (e) {
        // otherwise return unhandled
        return "Unhandled  Error.";
    }
}

export default getErrorText;