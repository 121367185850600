import React, { FC, useEffect, useState } from 'react';
import { Organization } from "nuaudit-browser-autogen";
import { useLocalStorage } from '@rehooks/local-storage';

import './App.less';

import CreateOrganization from './CreateOrganization';
import SelectOrganization from './SelectOrganization';
import { listOrganizations } from './services/rest';
import { message } from 'antd';
import Loading from './Loading';
import getErrorText from './utils/getErrorText';
import Dashboard from './Dashboard';

const Main: FC = () => {
  const [organizations, setOrganizations] = useState<Organization[] | null>()
  const [selectedOrganizationId, setSelectedOrganizationId] = useLocalStorage("selectedOrganizationId")

  const selectedOrganization = organizations?.find(organization => organization.id === selectedOrganizationId)

  const isLoading = () => organizations === undefined

  const setSelectedOrganization = (organization: Organization) => {
    setSelectedOrganizationId(organization.id)
  }

  const refresh = async () => {
    try {
      const organizations = await listOrganizations()
      setOrganizations(organizations)
      if (organizations.length === 1) {
        setSelectedOrganization(organizations[0])
      }
    } catch(error: any) {
      if (error?.code === 401) {
        window.location.assign("/api/login")
      } else {
        setOrganizations(null)
        message.error(getErrorText(error))
      }
    }
  }
  
  useEffect(() => {
    (async () => {
      await refresh()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading() 
        ? <Loading />
        : organizations && organizations?.length > 0
            ? selectedOrganization
              ? <Dashboard organizations={organizations} selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization} refresh={refresh} />
              : <SelectOrganization organizations={organizations} setSelectedOrganization={setSelectedOrganization} />
            : <CreateOrganization />
  )
}


export default Main;