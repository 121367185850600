import { DeleteTwoTone, ExclamationCircleTwoTone, MenuOutlined, PlusOutlined, SaveTwoTone } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table, Typography } from "antd";
import { FC, useCallback, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from 'immutability-helper';
import debounce from "lodash.debounce";

const { Text } = Typography;

type Index = {order: number, indexPath: string};

interface DragableBodyRowProps {
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    type: string;
}

type DragableBodyRowPropsExtended = DragableBodyRowProps & React.HTMLAttributes<HTMLElement>

const DraggableBodyRow: FC<DragableBodyRowPropsExtended> = ({ index, moveRow, type, draggable, style, ...props}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isOver, isDownward }, drop] = useDrop({
    accept: type || "accept",
    collect: monitor => {
      const { index: dragIndex }: {index: number} = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        isDownward: dragIndex < index,
      };
    },
    drop: (item: {index: number}) => {
      if (item.index !== index) {
        moveRow(item.index, index);
      }
    },
  });
  const [, drag] = useDrag({
    type: type || "accept",
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  // https://github.com/react-dnd/react-dnd/issues/900#issuecomment-1041453906
  const inputFieldSelector = [
    "textarea:focus",
    "input:not([type=checkbox]):focus",
    "input:not([type=radio]):focus",
    "button:focus",
  ].join(",");

  const [isInputFieldFocused, setInputFieldFocused] = useState<boolean>(false);

  const onBlur = () => {
    setInputFieldFocused(false);
  };

  const onFocus = () => {
    setInputFieldFocused(
      ref?.current?.querySelector(inputFieldSelector) !== null
    );
  };

  return (
    <tr
        ref={ref}
        style={{ cursor: 'grab', ...style}}
        {...props}
        className={isOver
          ? isDownward
            ? "downward"
            : "upward"
          : ""
        }
        onBlur={onBlur}
        onFocus={onFocus}
        draggable={!isInputFieldFocused}
    />
  );
};


interface IndexesProps {
    indexes: Index[];
    updateIndexes: (indexes: string[]) => void;
    type: string;
    loading: boolean;
}

const Indexes: FC<IndexesProps> = ({indexes, updateIndexes, type, loading}) => {
    const [updatedIndexes, setUpdatedIndexes] = useState<Index[]>([...indexes])

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newIndexes: Index[] = update(indexes, {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, indexes[dragIndex]],
                ],
              })
            updateIndexes(newIndexes.map(ni => ni.indexPath))

            const newUpdatedIndexes: Index[] = update(updatedIndexes, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, updatedIndexes[dragIndex]],
              ],
            })
            setUpdatedIndexes(newUpdatedIndexes)

        }, [indexes, updatedIndexes, updateIndexes]
    )

    const debouncedSetUpdatedIndexes = debounce(setUpdatedIndexes, 100)

    const components = {
        body: {
          row: DraggableBodyRow,
        },
    };

    const columns = [
        {
          title: 'Sort',
          dataIndex: 'sort',
          width: 30,
          className: 'drag-visible',
          render: () => <MenuOutlined style={{ color: '#999' }} />,
        },
        {
            title: "Precedence",
            dataIndex: "order",
            index: "order",
        },
        {
            title: "Index Path",
            dataIndex: "indexPath",
            index: "indexPath",
            render: (indexPath: string, _record: Index, index: number) => <Input
              key={indexPath} 
              defaultValue={updatedIndexes[index]?.indexPath || indexPath} 
              onChange={(event) => debouncedSetUpdatedIndexes([
                ...updatedIndexes.slice(0, index),
                {order: index, indexPath: event.target.value}, 
                ...updatedIndexes.slice(index + 1)
              ])}              
            />
        },
        {
            dataIndex: "order",
            index: "order",
            render: (_order: number, _record: Index, index:number) => <Space wrap style={{float: "right"}}>
                <Button 
                  size={"small"} 
                  disabled={updatedIndexes[index] === undefined || indexes[index].indexPath === updatedIndexes[index].indexPath}
                  shape="round"
                  icon={<SaveTwoTone />} 
                  onClick={() => updateIndexes([
                      ...indexes.slice(0, index),
                      updatedIndexes[index], 
                      ...indexes.slice(index + 1)
                    ].map(index => index.indexPath)
                  )} >
                    Save
                </Button>
                <Popconfirm
                    title={<>
                        <Text strong>Delete this Index?</Text>
                    </>}
                    placement="bottomRight"
                    onConfirm={() => {updateIndexes([
                      ...indexes.slice(0, index),
                      ...indexes.slice(index + 1)
                    ].map(index => index.indexPath)
                  ); debouncedSetUpdatedIndexes([
                    ...updatedIndexes.slice(0, index),
                    ...updatedIndexes.slice(index + 1)
                  ])}}
                    okText="Yes"
                    cancelText="No"
                    icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                >
                    <Button 
                      danger 
                      size="small"
                      shape="round" 
                      type="dashed" 
                      icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>
                        Delete
                    </Button>
                </Popconfirm>
            </Space>        
        }
    ]

    return (
        <DndProvider backend={HTML5Backend}>
            <Table
                footer={() => 
                  <Button
                    shape="round" 
                    icon={<PlusOutlined />}
                    onClick={() => {updateIndexes(indexes.map(index => index.indexPath).concat([""]));
                    debouncedSetUpdatedIndexes(updatedIndexes.concat([indexes[-1]]))
                  }}
                  >
                    Add Index
                  </Button>}
                columns={columns}
                dataSource={indexes}
                components={components}
                onRow={(record, index) => ({index, moveRow, type} as DragableBodyRowPropsExtended)}
                pagination={false}
            />
        </DndProvider>
    )
}

export default Indexes