import React, { FC, useState } from "react"
import { Button, Card, Col, Form, Input, Layout, message, Row, Space, Spin } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons";
import { OrganizationMutation } from "nuaudit-browser-autogen";
import { createOrganization } from "./services/rest";
import getErrorText from "./utils/getErrorText";

const { Content } = Layout;

const CreateOrganization: FC = () => {
    const [loading, setLoading] = useState(false)

    const onFinish = async (organizationMutation: OrganizationMutation) => {
        setLoading(true)
        try {
            await createOrganization(organizationMutation)
            window.location.reload()
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setLoading(false)
    }

    return <Layout>
        <Content>
            <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                <Col>
                    <Card title="nuaudit.">
                        <Spin spinning={loading}>
                            <Space direction="vertical">
                                <Form layout="vertical" requiredMark={false} onFinish={onFinish}>
                                    <Form.Item
                                    label="Create an Organization"
                                    name="name"
                                    rules={[{ required: true, message: 'Required.' }]}
                                    tooltip={{ title: 'This can be changed later.', icon: <InfoCircleOutlined /> }}
                                    >
                                        <Input placeholder="Organization Name"  autoComplete="off" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Create</Button>
                                    </Form.Item>
                                </Form>
                            </Space>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </Content>
    </Layout>}

export default CreateOrganization;

