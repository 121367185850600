import { Button, Result } from "antd"
import React, { FC } from "react"
import { Link } from "react-router-dom"

interface NotFoundProps {
    message: string,
    linkTo: string
}

const NotFound: FC<NotFoundProps> = ({message, linkTo}) => <Result
    status="404"
    title="404"
    subTitle={message}
    extra={<Link to={linkTo}><Button type="primary">Return</Button></Link>}
/>

export default NotFound