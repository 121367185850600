import React, { FC, useEffect, useState } from "react"
import {  Button, Descriptions, Dropdown, Menu, message, PageHeader, Popconfirm, Popover, Space, Spin, Table, Typography } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { Trail as TrailType, Record, ActorRecord, ResourceRecord } from "nuaudit-browser-autogen";
import { format, formatDistanceToNow } from 'date-fns'
import OrganizationProps from "../interfaces/OrganizationProps";
import { deleteTrail, getTrail, listTrailRecords, reindex } from "../services/rest";
import { DeleteTwoTone, DiffOutlined, ExclamationCircleTwoTone, RedoOutlined } from "@ant-design/icons";
import ResourceRecordCol from "../components/ResourceRecordCol";
import htmlDiff from "../utils/htmlDiff";
import getErrorText from "../utils/getErrorText";

const { Text, Title } = Typography;

const Trail: FC<OrganizationProps> = ({organizationId}) => {
    const { id } = useParams<{id: string}>();

    const history = useHistory();

    const [trail, setTrail] = useState<TrailType | null>()
    const [records, setRecords] = useState<Record[]>([]);
    const [loading, setLoading] = useState(true);

    const _reindex = async (organizationId: string, trailId: string, recordId: string) => {
        try {
            await reindex(organizationId, trailId, recordId)
            message.success("Sent a record for reindexing. This can take some time to complete.")
        } catch(error: any) {
            message.error(getErrorText(error))
        }
    }

    const _deleteTrail = async (organizationId: string, trailId: string) => {
        setLoading(true)
        await deleteTrail(organizationId, trailId)
        message.success("Trail Deleted.")
        history.replace("/trails")
    }

    useEffect(() => {
        (async () => {
            let trail: TrailType | null = null;
            let records: Record[] = []
            try {
                trail = await getTrail(organizationId, id)
                records = await listTrailRecords(organizationId, trail.id)
            } catch(error: any) {
                message.error(getErrorText(error))
                if (error?.code === 404) {
                    history.replace("/trails")
                }
            }
            setTrail(trail)
            setRecords(records);
            setLoading(false)
        })()
    }, [organizationId, history, id])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => 
            <>
                <Text>{format(new Date(createdAt), "pppp")}</Text>
                <br />
                <Text type="secondary">{formatDistanceToNow(new Date(createdAt), { addSuffix: true })}</Text>
            </>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Resource',
            dataIndex: 'resourceRecord',
            key: 'resourceRecord',
            render: (_: ResourceRecord, record: Record) => <ResourceRecordCol record={record} organizationId={organizationId}/>
        },
        {
            title: 'Actor',
            dataIndex: 'actorRecord',
            key: 'actorRecord',
            render: (actorRecord: ActorRecord) => 
            actorRecord.actor
                ?   <>    
                        <Link to={`/actors/${actorRecord.actor.id}`}>{actorRecord.actor.metadata.derivedId}</Link>
                        <br />
                        <Text type="secondary">{actorRecord.actor.metadata.derivedType}</Text>
                    </>
                :   <Space wrap>
                        <Text type="secondary">Unknown</Text>
                        <Popover
                            content={<div dangerouslySetInnerHTML={{ __html: htmlDiff({}, actorRecord.data)}}/>}
                            trigger="click"
                            placement="bottomRight"
                        >
                            <Button icon={<DiffOutlined />} shape="circle" size="small" />
                        </Popover>
                    </Space>
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, record: Record) => <Dropdown.Button 
                type={"text" as any}
                overlay={
                    <Menu>
                        <Menu.Item 
                            key="1" 
                            onClick={() => trail && _reindex(organizationId, trail?.id, record.id)}
                            icon={<RedoOutlined />}
                        >
                            Reindex
                        </Menu.Item>
                    </Menu>
                }
            />
        }
    ];

    return (
        <Spin spinning={loading}>
            <PageHeader 
                title={trail?.name} 
                subTitle={trail?.id} 
                onBack={() => window.history.back()}                             
                extra={[
                    <Popconfirm
                        title={<>
                            <Text strong>Are you sure to delete this Trail?</Text>
                            <br/>
                            <Text type="danger">This action will delete records.</Text>
                        </>}
                        placement="bottomRight"
                        onConfirm={() => trail && _deleteTrail(organizationId, trail?.id)}
                        okText="Yes"
                        cancelText="No"
                        icon={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}
                    >
                        <Button danger shape="round" type="dashed" icon={<DeleteTwoTone twoToneColor="#ff7875"/>}>Delete Trail</Button>
                    </Popconfirm>
                ]}>
                <Descriptions>
                    <Descriptions.Item>{trail?.description}</Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <Table title={() => <Title level={4}>Records</Title>} dataSource={records} columns={columns} pagination={{position: ["bottomLeft"]}} />
        </Spin>
    )
}

export default Trail