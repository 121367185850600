import { SearchOutlined } from "@ant-design/icons";
import { Button, Popover, Space, Typography } from "antd"
import { Record } from "nuaudit-browser-autogen"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import htmlDiff from "../utils/htmlDiff";

const { Text } = Typography;

interface ActorRecordColProps {
    record: Record
}

const ActorRecordCol: FC<ActorRecordColProps> = ({record}) => {     
    const actorRecord = record.actorRecord
    
    return actorRecord.actor 
        ?   <>
                <Link to={`/actors/${actorRecord.actor.id}`}>{actorRecord.actor.metadata.derivedId}</Link>
                <br />
                <Space wrap>
                    <Text type="secondary">{actorRecord.actor.metadata.derivedType}</Text>
                    <Popover
                    content={<div dangerouslySetInnerHTML={{ __html: htmlDiff({}, actorRecord.data)}}/>}
                    trigger="click"
                    placement="bottomRight"
                >
                    <Button icon={<SearchOutlined />} shape="circle" size="small" />
                </Popover>
                </Space>
            </>
        :   <Space wrap>
                <Text type="secondary">Unknown</Text>
                <Popover
                    content={<div dangerouslySetInnerHTML={{ __html: htmlDiff({}, actorRecord.data)}}/>}
                    trigger="click"
                    placement="bottomRight"
                >
                    <Button icon={<SearchOutlined />} shape="circle" size="small" />
                </Popover>
            </Space>
} 

export default ActorRecordCol