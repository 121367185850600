import { message, PageHeader, Statistic, Table, Typography } from "antd";
import { Metadata, Resource } from "nuaudit-browser-autogen"
import React, { FC, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import DateCol from "../components/DateCol";
import OrganizationProps from "../interfaces/OrganizationProps";
import { listResources } from "../services/rest";
import getErrorText from "../utils/getErrorText";

const { Text } = Typography;

const Resources: FC<OrganizationProps> =  ({organizationId}) => {
    const [resources, setResources] = useState<Resource[]>();

    useEffect(()=> {
        (async () => {
            let resources: Resource[] = []
            try {
                resources = await listResources(organizationId)
            } catch(error: any) {
                message.error(getErrorText(error))
            }
            setResources(resources)
        })()
    }, [organizationId])

    const columns = [
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdatedAt',
            key: 'lastUpdatedAt',
            render: (lastUpdatedAt: string) => <DateCol date={lastUpdatedAt} />
        },
        {
            title: 'Resource',
            dataIndex: 'metadata',
            key: 'metadata',
            render: (metadata: Metadata, resource: Resource) => <>
                <Link to={`/resources/${resource.id}`}>{metadata.derivedId}</Link>
                <br />
                <Text type="secondary">{metadata.derivedType}</Text>
            </>
        },
        {
            title: 'Total Records',
            dataIndex: 'totalRecords',
            key: 'totalRecords',
            render: (totalRecords: string) => <Statistic value={totalRecords} />
        },
        {
            title: 'Trail',
            dataIndex: 'trailId',
            key: 'trailId',
            render: (trailId: string) => <Link to={`/trails/${trailId}`}>{trailId}</Link>
        }
    ]
    return (
        <PageHeader title="Resources" onBack={() => window.history.back()}>
            <Table loading={resources === undefined} columns={columns} dataSource={resources} pagination={{position: ["bottomLeft"]}} />
        </PageHeader>
    )
}
    
export default Resources