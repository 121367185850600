import { Descriptions, message, PageHeader, Spin, Table, Typography } from "antd";
import React, { FC, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import OrganizationProps from "../interfaces/OrganizationProps";
import {Actor as ActorType, Record, ResourceRecord} from "nuaudit-browser-autogen"
import { getActor, listActorRecords } from "../services/rest";
import { format } from 'date-fns'
import ResourceRecordCol from "../components/ResourceRecordCol";
import DateCol from "../components/DateCol";
import htmlDiff from "../utils/htmlDiff";
import getErrorText from "../utils/getErrorText";

const { Text, Title } = Typography;

const Actor: FC<OrganizationProps> = ({organizationId}) => {
    const { id } = useParams<{id: string}>();

    const history = useHistory();

    const [actor, setActor] = useState<ActorType | null>()
    const [records, setRecords] = useState<Record[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let actor: ActorType | null = null;
            let records: Record[] = []

            try {
                actor = await getActor(organizationId, id)
                records = await listActorRecords(organizationId, id)
            } catch(error: any) {
                message.error(getErrorText(error))
                if (error?.code === 404) {
                    history.replace("/actors")
                }
            }
            setActor(actor)
            setRecords(records)
            setLoading(false)
        })()
    }, [organizationId, history, id])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => <DateCol date={createdAt} />
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Resource',
            dataIndex: 'resourceRecord',
            key: 'resourceRecord',
            render: (_: ResourceRecord, record: Record) => <ResourceRecordCol record={record} organizationId={organizationId}/>
        }
    ];

    return (
        <Spin spinning={loading}>
            <PageHeader title={actor?.metadata.derivedId} subTitle={actor?.metadata.derivedId} onBack={() => window.history.back()}>
                <Descriptions column={1} bordered={true}>
                    {actor && <Descriptions.Item label="Last Seen At"><Text>{format(new Date(actor.lastSeenAt), "pppp")}</Text></Descriptions.Item>}
                    {records.length && <Descriptions.Item label="Latest Data">
                        <div dangerouslySetInnerHTML={{ __html: htmlDiff({}, records[0].actorRecord.data)}}/>
                    </Descriptions.Item>}
                </Descriptions>
            </PageHeader>
            <Table title={() => <Title level={4}>Records</Title>} dataSource={records} columns={columns} pagination={{position: ["bottomLeft"]}} />
        </Spin>
    )}

export default Actor