import { CaretRightOutlined, SaveTwoTone } from "@ant-design/icons";
import { Button, Collapse, Input, message, PageHeader, Space, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import OrganizationProps from "../interfaces/OrganizationProps";
import { getSettingsForOrganization, updateSettingsForOrganization } from "../services/rest";
import { Settings as Settings_, Indexes as Indexes_ } from "nuaudit-browser-autogen";
import getErrorText from "../utils/getErrorText";
import Indexes from "../components/Indexes";
import debounce from "lodash.debounce";

const { Panel } = Collapse;

const Settings: FC<OrganizationProps> = ({organizationId}) => {
    const [settings, setSettings] = useState<Settings_ | null>()
    const [loading, setLoading] = useState(false)
    const [updatedRetentionPeriod, setUpdatedRetentionPeriod] = useState<number>()

    const updateIndexes = async (indexType: keyof Indexes_, indexes: string[]) => {
      if (!settings) {
        return
      }

      const newIndexes = {...settings.indexes, [indexType]: indexes}
      const newSettings = {...settings, indexes: newIndexes}
      
      setLoading(true)
      try {
        const updatedSettings = await updateSettingsForOrganization(organizationId, newSettings)
        setSettings(updatedSettings)
        message.success("Saved Organization Settings.")
      } catch(error: any) {
        message.error(getErrorText(error))
      }
      setLoading(false)
    }

    const updateRetentionPeriod = async (settings: Settings_, retentionPeriod: number) => {
      setLoading(true)
      try {
        const updatedSettings = await updateSettingsForOrganization(
          organizationId,
          {...settings, retentionPeriod: retentionPeriod}
        )
        setSettings(updatedSettings)
        message.success("Saved Organization Settings.")
      } catch(error: any) {
        message.error(getErrorText(error))
      }
      setLoading(false) 
    }

    useEffect(()=> {
      (async () => {
        setLoading(true)
        let settings: Settings_ | null = null
        try {
            settings = await getSettingsForOrganization(organizationId)
            setUpdatedRetentionPeriod(settings.retentionPeriod)
        } catch(error: any) {
            message.error(getErrorText(error))
        }
        setSettings(settings)   
        setLoading(false)
      })()
    },[organizationId])

    const debouncedSetUpdatedRetentionPeriod = debounce(setUpdatedRetentionPeriod, 100)

    return (
        <Spin spinning={loading}>
          <PageHeader title="Index Settings" >
            {settings && <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                style={{maxWidth: 1024}}
            >
                <Panel header="Resource Type" key="1">
                    <Indexes 
                      indexes={settings?.indexes.resourceType.map((rt, i) => (
                        {"order": i, "indexPath": rt}
                      ))}
                      updateIndexes={(indexes) => updateIndexes("resourceType", indexes)}
                      type="resourceType"
                      loading={loading}
                    />                    
                </Panel>
                <Panel header="Resource ID" key="2">
                  <Indexes 
                    indexes={settings?.indexes.resourceId.map((ri, i) => (
                      {"order": i, "indexPath": ri}
                    ))}
                    updateIndexes={(indexes) => updateIndexes("resourceId", indexes)}
                    type="resourceId"
                    loading={loading}
                  />  
                </Panel>
                <Panel header="Actor Type" key="3">
                  <Indexes 
                    indexes={settings?.indexes.actorType.map((rt, i) => (
                      {"order": i, "indexPath": rt}
                    ))}
                    updateIndexes={(indexes) => updateIndexes("actorType", indexes)}
                    type="actorType"
                    loading={loading}
                  />
                </Panel>
                <Panel header="Actor ID" key="4">
                <Indexes 
                    indexes={settings.indexes.actorId.map((ii, i) => (
                      {"order": i, "indexPath": ii}
                    ))}
                    updateIndexes={(indexes) => updateIndexes("actorId", indexes)}
                    type="actorId"
                    loading={loading}
                  />
                </Panel>
            </Collapse>}
        </PageHeader>
        <PageHeader title="Retention Settings" >
          {settings && updatedRetentionPeriod && <Space wrap>
              <Input 
                type="number" 
                addonAfter="days" 
                defaultValue={settings.retentionPeriod} 
                style={{maxWidth:172}}
                onChange={(event) => debouncedSetUpdatedRetentionPeriod(+event.target.value)}
              />
              <Button 
                shape="round" 
                icon={<SaveTwoTone />}
                disabled={updatedRetentionPeriod === settings.retentionPeriod}
                onClick={() => updateRetentionPeriod(settings, updatedRetentionPeriod)}>
                  Save
              </Button>
          </Space>}
        </PageHeader>
      </Spin>
    )
}

export default Settings;